import { ModuleWithProviders, NgModule } from '@angular/core'
import { IDLE_DETECTOR_CONFIG } from '../..'
import { IdleDetectorConfig } from './interfaces/idle-detector-config'

const DEFAULT_RESET_EVENTS = ['mousemove', 'mousedown', 'scroll', 'keydown', 'touchstart']

export type IdleModuleConfig = Partial<IdleDetectorConfig>

@NgModule({})
export class IdleModule {
  static forRoot(config: IdleModuleConfig): ModuleWithProviders<IdleModule> {
    config = { resetEvents: DEFAULT_RESET_EVENTS, ...config }

    return {
      ngModule: IdleModule,
      providers: [{ provide: IDLE_DETECTOR_CONFIG, useValue: config }],
    }
  }
}
