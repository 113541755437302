import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { MessageService } from 'primeng/api'
import { VerificarInformacionCorreoService } from '../../../apis/usuario/correo.informacion.service'
import { UsuariosUsuarioInformacionService } from '../../../apis/usuario/informacion.service'
import { IAuthUsuariosUsuarioInformacion } from '../../../types'

@Component({
  selector: 'auth-usuarios-usuario-informacion',
  templateUrl: './informacion.component.html',
  styleUrls: ['./informacion.component.scss'],
})
export class AuthUsuariosUsuarioInformacionComponent implements OnInit {
  @Input() data: IAuthUsuariosUsuarioInformacion
  copia: IAuthUsuariosUsuarioInformacion
  @Input() activo
  error = ''
  editando = false
  finalizado = true
  usuarioId: number
  correoInfExiste = false

  constructor(
    private usuariosUsuarioInformacionService: UsuariosUsuarioInformacionService,
    private infCorreoService: VerificarInformacionCorreoService,
    private routedActived: ActivatedRoute,
    private messageService: MessageService,
  ) {
    this.usuarioId = this.routedActived.snapshot.params.usuarioId
  }

  ngOnInit(): void {
    this.copia = Object.assign({}, this.data)
  }

  guardar(): void {
    if (this.correoInfExiste || this.editando) {
      return
    }

    this.error = ''
    this.editando = true

    this.usuariosUsuarioInformacionService
      .put(this.copia, this.usuarioId)
      .toPromise()
      .then(() => {
        this.editando = false
        this.data = this.copia
        this.finalizado = true
        this.messageService.add({
          severity: 'success',
          life: 1400,
          closable: false,
          summary: 'Usuario',
          detail: 'Se actualizó la información del usuario',
        })
      })
      .catch(({ error }) => {
        this.error = error.error || error
      })
  }

  verificarInfCorreo(): void {
    this.copia.correo = this.copia.correo.trim()
    this.infCorreoService
      .get(this.copia.correo, this.usuarioId)
      .toPromise()
      .then((data) => (this.correoInfExiste = data))
  }
}
