import { IsNumber, IsOptional, IsString } from 'class-validator'

export class CatalogoMunicipioDto {
  @IsNumber()
  id?: number

  @IsString()
  descripcion!: string

  @IsOptional()
  @IsNumber()
  estadoId?: number
}
