<section class="card rounded mt-4">
  <form class="flex px-4 h-12 items-center gap-2" name="form" #form="ngForm" (submit)="guardar()">
    <div class="flex-grow typo-subhead">Permisos</div>
    <button
      class="button"
      type="submit"
      [disabled]="!modificado"
      [ngClass]="{ 'bg-blue-200 hover:bg-blue-200': !modificado }"
    >
      Editar
    </button>
  </form>
  <div class="flex text-center px-4">
    <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
    <div class="flex-grow">
      <input
        pInputText
        autocomplete="off"
        [(ngModel)]="filtro"
        (ngModelChange)="filtrar()"
        class="input-filter"
        id="username"
        type="search"
        placeholder=""
      />
    </div>
  </div>
  <div class="max-h-72 h-72 overflow-auto mt-2">
    <auth-usuarios-usuario-dominio-permisos-lista
      (selected)="seleccionar()"
      [data]="resultados"
    ></auth-usuarios-usuario-dominio-permisos-lista>
  </div>
</section>
<p-toast position="bottom-right"></p-toast>
