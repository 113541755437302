import { IsNumber, IsString } from 'class-validator'

class CatalogoDto {
  @IsNumber()
  id!: number

  @IsString()
  descripcion!: string
}

export class PosCatalogoTipoProductoDto extends CatalogoDto {}

export class PosCatalogoFamiliaDto extends CatalogoDto {}
export class PosCatalogoDepartamentoDto extends CatalogoDto {}
export class PosCatalogoGrupoDto extends CatalogoDto {}
export class PosCatalogoUnidadMedidaDto extends CatalogoDto {}
export class PosCatalogoTipoCompraDto extends CatalogoDto {}
export class PosCatalogoTipoVentaDto extends CatalogoDto {}
export class PosCatalogoImpuestoDto extends CatalogoDto {
  @IsNumber()
  tasa!: number
}
export class PosCatalogoLineaDto extends CatalogoDto {}
