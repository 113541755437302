// eslint-disable-next-line no-misleading-character-class
export const diacriticosRegex = /[,.\u0300-\u036f]/g
export const diacriticosEspacios = /\s+\s/g
// eslint-disable-next-line no-useless-escape
export const caracteresEspeciales = /([!#$%&"()*+,-./:;<=>?@\^_`{|}~])/g
export const normalizar = (valor: string) =>
  valor
    .normalize('NFD')
    .replace(diacriticosRegex, '')
    .replace(diacriticosEspacios, ' ')
    .replace(caracteresEspeciales, '')
    .replace(' ', '-')
    .toLowerCase()
    .trim()

export const normalizar2 = (valor: string) =>
  valor
    .normalize('NFD')
    .toLowerCase()
    .replace(diacriticosRegex, '')
    .replace(diacriticosEspacios, ' ')
    .trim()
    .replace(/[^-a-z0-9_ ]/g, '')
    .trim()
    .replace(/ /g, '-')
