// eslint-disable-next-line no-misleading-character-class
const diacriticosRegex = /[,.\u0300-\u036f]/g
const diacriticosEspacios = /\s+\s/g

export const normalizarFiltro = (valor: string) =>
  valor.normalize('NFD').replace(diacriticosRegex, '').replace(diacriticosEspacios, ' ').toUpperCase().trim()

export const normalizar = (valor: string) =>
  valor
    .normalize('NFD')
    .toLowerCase()
    .replace(diacriticosRegex, '')
    .replace(diacriticosEspacios, ' ')
    .trim()
    .replace(/[^-a-z0-9_ ]/g, '')
    .trim()
    .replace(/ /g, '-') ?? ''
