import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { IUsuarioPermiso } from '../../../../../../../../../types'

@Component({
  selector: 'auth-usuarios-usuario-empresa-permisos-lista-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class AuthUsuariosUsuarioEmpresaPermisosListaItemComponent implements OnInit {
  @Input() data: IUsuarioPermiso
  @Output() selected: EventEmitter<void> = new EventEmitter()
  seleccionadoPreviamente = false

  ngOnInit(): void {
    this.seleccionadoPreviamente = this.data.seleccionado
  }

  seleccion(): void {
    this.data.seleccionado = !this.data.seleccionado
    this.data.eliminado = this.seleccionadoPreviamente && !this.data.seleccionado
    this.data.modificado = this.seleccionadoPreviamente !== this.data.seleccionado
    this.selected.emit()
  }
}
