import { Type } from 'class-transformer'
import { IsEmail, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { CatalogoCiudadDto, CatalogoEstadoDto, CatalogoMunicipioDto, CatalogoZonaDto } from '@decet/catalogo-shared'
import { DireccionDto } from '@decet/direcciones-shared'
import 'reflect-metadata'

export class SucursalTipoDto {
  @IsNumber()
  id!: number

  @IsOptional()
  @IsString()
  descripcion!: string
}

export class SucursalDireccionDto {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsString()
  calle!: string

  @ValidateNested()
  @Type(() => CatalogoZonaDto)
  zona!: CatalogoZonaDto

  @ValidateNested()
  @Type(() => CatalogoCiudadDto)
  ciudad!: CatalogoCiudadDto

  @ValidateNested()
  @Type(() => CatalogoMunicipioDto)
  municipio!: CatalogoMunicipioDto

  @ValidateNested()
  @Type(() => CatalogoEstadoDto)
  estado!: CatalogoEstadoDto

  @IsString()
  numeroExterior!: string

  @IsOptional()
  @IsNumber()
  latitud?: number

  @IsOptional()
  @IsNumber()
  longitud?: number
}

export class SucursalDto {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsOptional()
  @IsNumber()
  cuentaId?: number

  @IsOptional()
  @IsNumber()
  empresaId?: number

  @IsString()
  descripcion!: string

  @ValidateNested()
  @Type(() => SucursalTipoDto)
  sucursalTipo?: SucursalTipoDto

  @ValidateNested()
  @Type(() => DireccionDto)
  direccion!: DireccionDto | null

  @IsOptional()
  @IsString()
  telefono?: string

  @IsOptional()
  @IsString()
  correo?: string

  @IsOptional()
  @IsNumber()
  zonaId?: number

  @IsOptional()
  @IsString()
  busqueda?: string
}
export class SucursalesDto {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsString()
  @IsNotEmpty()
  calle!: string

  @IsOptional()
  @IsString()
  @IsEmail()
  correo?: string

  @IsString()
  @IsNotEmpty()
  descripcion!: string

  @IsString()
  @IsNotEmpty()
  numeroExterior!: string

  @IsNumber()
  sucursalTipoId!: number

  @IsOptional()
  @IsString()
  telefono?: string

  @IsOptional()
  @IsNumber()
  zonaId?: number
}
