import { Component, EventEmitter, Input, Output } from '@angular/core'
import { IAgrupacionesUsuarioPermisos } from '../../../../../../../../types'

@Component({
  selector: 'auth-usuarios-usuario-empresa-permisos-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss'],
})
export class AuthUsuariosUsuarioEmpresaListaComponent {
  @Input() data: IAgrupacionesUsuarioPermisos
  @Output() selected = new EventEmitter<void>()

  seleccionar(): void {
    this.selected.emit()
  }
}
