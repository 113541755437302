import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class VerificarCorreoService {
  constructor(@Inject(HttpClient) private http: HttpClient) {}

  get(correo: string): Observable<boolean> {
    return this.http.get<boolean>(`/api/auth/usuarios/correo`, {
      params: { correo },
    })
  }
}
