import { debounce, DebounceSettings } from 'lodash'

export function Debounce(delay: number, options?: DebounceSettings) {
  return function (_target: Object, _propertyKey: string | symbol, descriptor: PropertyDescriptor) {
    if (descriptor.set) {
      descriptor.set = debounce(descriptor.set, delay, options)
    } else if (descriptor.get) {
      descriptor.get = debounce(descriptor.get, delay, options)
    } else if (descriptor.value) {
      descriptor.value = debounce(descriptor.value, delay, options)
    }
  }
}
