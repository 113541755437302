<!--<app-header>-->
<div class="toolbar-container">
  <div class="toolbar-container-header">
    <div class="toolbar-header">
      <div class="__header-title typo-title"></div>
    </div>
  </div>
</div>
<!--</app-header>-->

<!--<app-content>-->
<div class="text-align-center" style="text-align: center">
  <h1 class="typo-display --margin-xl">Sesión Terminada</h1>
  <div class="--margin-lg" style="margin-bottom: 1rem">
    Su sesión ha terminado, muchas gracias por utilizar nuestros servicios.
  </div>
  <button class="p-button --margin-md" type="button" routerLink="/sesion/login" [queryParams]="{ redirect: '' }">
    Acceder al sistema
  </button>
</div>
<!--app-content>-->
