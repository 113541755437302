import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { RouterModule } from '@angular/router'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { CheckboxModule } from 'primeng/checkbox'
import { MenuModule } from 'primeng/menu'
import { ProgressBarModule } from 'primeng/progressbar'
import { TooltipModule } from 'primeng/tooltip'
import { AuthSesionModuloService } from '../usuarios/apis/modulo.service'
import { AuthSesionJwtService } from './apis/jwt.service'
import { AuthSesionLoginService } from './apis/login.service'
import { AuthSesionReestablecerService } from './apis/reestablecer.service'
import { AuthSesionReestablecerCambiarService } from './apis/reestablecer/cambiar.service'
import { AuthSesionAutorizacionComponent } from './components/autorizacion/autorizacion.component'
import { AuthSesionCurrentComponent } from './components/current/current.component'
import { AuthSesionFinalizarComponent } from './components/finalizar/finalizar.component'
import { AuthSesionLoginComponent } from './components/login/login.component'
import { AuthSesionReestablecerComponent } from './components/reestablecer/reestablecer.component'
import { AuthSesionRegistrarComponent } from './components/registrar/registrar.component'
import { AuthCurrentComponent } from './current/current.component'
import { AuthSesionCurrentMenuComponent } from './current/menu/menu.component'
import { AuthSesionPermisosService } from './services/permisos.service'
import { AutSesionRoutingModule } from './sesion-routing.module'
import { MultiSelectModule } from 'primeng/multiselect'
import { DropdownModule } from 'primeng/dropdown'
import { MatInputModule } from '@angular/material/input'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatListModule } from '@angular/material/list'
import { MatRippleModule } from '@angular/material/core'
import { MatMenuModule } from '@angular/material/menu'
import { AuthSesionCurrentMenuItemComponent } from './current/menu-item/menu-item.component'
import { AuthSesionCurrentWorkspaceComponent } from './current/workspace/workspace.component'

@NgModule({
  declarations: [
    AuthSesionLoginComponent,
    AuthSesionAutorizacionComponent,
    AuthSesionFinalizarComponent,
    AuthSesionReestablecerComponent,
    AuthSesionRegistrarComponent,
    AuthCurrentComponent,
    AuthSesionCurrentComponent,
    AuthSesionCurrentMenuComponent,
    AuthSesionCurrentMenuItemComponent,
    AuthSesionCurrentWorkspaceComponent,
  ],
  imports: [
    CommonModule,
    AutSesionRoutingModule,
    ProgressBarModule,
    CheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    TooltipModule,
    MenuModule,
    MatListModule,
    MatRippleModule,
    MatMenuModule,
    MatButtonModule,
    MatInputModule,
    MatSnackBarModule,
    FontAwesomeModule,
    // StoreModule.forFeature('sesion', permisos),
    // StoreDevtoolsModule.instrument({
    //   maxAge: 25,
    //   logOnly: environment.production,
    // }),
    FontAwesomeModule,
    MultiSelectModule,
    DropdownModule,
  ],
  exports: [AuthCurrentComponent, AuthSesionCurrentComponent],
  providers: [],
})
export class AuthSesionModule {}
