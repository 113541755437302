import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { assert, isInteger } from '@decet/core/shared/common'
import { NETWORK_CONFIG } from './constants/network-configuration'
import { ConcurrentInterceptor } from './interceptors/concurrent.interceptor'
import { OfflineErrorInterceptor } from './interceptors/offline-error.interceptor'
import { RetryInterceptor } from './interceptors/retry.interceptor'
import { NetworkModuleConfig } from './interfaces/network-configuration'

const DEFAULT_CONFIGURATION: NetworkModuleConfig = {
  'app.network.retry.attempts': 0,
  'app.network.retry.delay': 3000,
  'app.network.retry.when': (error) => error.status === 500,
  'app.network.concurrent.interval': 100,
}

function createConfig(config: Partial<NetworkModuleConfig>): NetworkModuleConfig {
  const configuration: NetworkModuleConfig = { ...DEFAULT_CONFIGURATION, ...config }

  const retryAttempts = configuration['app.network.retry.attempts']
  assert(isInteger(retryAttempts) && retryAttempts >= 0, `Config app.network.retry.attempts must be an integer >= 0`)

  const retryDelay = configuration['app.network.retry.delay']
  assert(isInteger(retryDelay) && retryDelay > 0, `Config app.network.retry.delay must be an integer > 0`)

  const retryWhen = configuration['app.network.retry.when']

  const concurrentInterval = configuration['app.network.concurrent.interval']
  assert(
    isInteger(concurrentInterval) && concurrentInterval >= 0,
    `Config app.network.concurrent.interval must be an integer >= 0`,
  )

  return {
    'app.network.retry.attempts': retryAttempts,
    'app.network.retry.delay': retryDelay,
    'app.network.retry.when': retryWhen,
    'app.network.concurrent.interval': concurrentInterval,
  }
}

@NgModule()
export class NetworkModule {
  static forRoot(configuration: Partial<NetworkModuleConfig> = {}): ModuleWithProviders<NetworkModule> {
    const config = createConfig(configuration)

    return {
      ngModule: NetworkModule,
      providers: [
        { provide: NETWORK_CONFIG, useValue: config },
        { provide: HTTP_INTERCEPTORS, useClass: ConcurrentInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: OfflineErrorInterceptor, multi: true },
        config['app.network.retry.attempts'] > 0
          ? { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true }
          : [],
      ],
    }
  }
}
