<div class="card rounded mt-4 p-4 gap-2 flex flex-col">
  <button class="button self-start" type="button" (click)="restablecer()">Restablecer contraseña</button>
  <button class="button button-error self-start" type="button" (click)="eliminar()">Eliminar usuario</button>
</div>
<auth-usuarios-usuario-configuracion-confirmar-eliminar
  *ngIf="respuesta"
  (cerrar)="eliminarResponse()"
></auth-usuarios-usuario-configuracion-confirmar-eliminar>
<auth-usuarios-usuario-configuracion-reestablecer-confirmar
  *ngIf="bandera"
  [correo]="data.correo"
  (cerrar)="restablecerResponse()"
></auth-usuarios-usuario-configuracion-reestablecer-confirmar>
