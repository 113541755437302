import { registerLocaleData } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { CardModule } from 'primeng/card'
import { DropdownModule } from 'primeng/dropdown'
import { APP_ENVIRONMENT } from '@decet/core/browser/common'
import { AuthModule } from '@inmueble/auth'
import { InterceptorProvider } from '../../../../../libs/auth/src/lib/sesion/interceptor/interceptor'
import { pos } from '../../../../pos/src/app/store/pos.reducer'
import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { DecetCrmModule } from './crm/crm.module'
import { NxWelcomeComponent } from './nx-welcome.component'

function loadLocales(LOCALE_ID: string) {
  return async () => {
    switch (LOCALE_ID) {
      case 'es-MX':
        const localeMx = (await import('@angular/common/locales/es-MX')).default
        registerLocaleData(localeMx)
        break
    }
  }
}

@NgModule({
  declarations: [AppComponent, NxWelcomeComponent],
  imports: [
    BrowserModule,
    DecetCrmModule,
    AppRoutingModule,
    AuthModule,
    DropdownModule,
    CardModule,
    HttpClientModule,
    BrowserAnimationsModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: true,
    }),
    StoreRouterConnectingModule.forRoot({ routerState: RouterState.Minimal }),
    StoreModule.forRoot({ pos }),
  ],
  providers: [
    InterceptorProvider,
    // { provide: HTTP_INTERCEPTORS, useClass: DatabaseInterceptor, multi: true },
    { provide: APP_ENVIRONMENT, useValue: environment },
    { provide: LOCALE_ID, useValue: document.documentElement.lang },
    { provide: APP_INITIALIZER, useFactory: loadLocales, deps: [LOCALE_ID], multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
