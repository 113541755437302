import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, retryWhen, switchMap, throwError } from 'rxjs'
import { NetworkStatusService } from '../services/network-status.service'

@Injectable()
export class OfflineErrorInterceptor {
  constructor(private networkStatusService: NetworkStatusService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen((errors: Observable<HttpErrorResponse>) => {
        if (this.networkStatusService.isOnline) {
          return errors.pipe(switchMap((response) => throwError(() => response)))
        }

        return this.networkStatusService.onlineChange$
      }),
    )
  }
}
