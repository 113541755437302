import { IsNumber, IsOptional, IsString } from 'class-validator'
import { asDTO } from '@decet/core/shared/common'

export class ExChangeRate {
  @IsNumber()
  id!: number

  @IsOptional()
  @IsString()
  codigo?: string

  @IsString()
  descripcion!: string

  @IsNumber()
  tipoCambio!: number
}

export const ExChangeRateDTO = asDTO(ExChangeRate)
