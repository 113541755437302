import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { UsuariosUsuarioEstatusService } from '../../../apis/usuario/estatus.service'
import { IUsuarioEstatus } from '../../../types'

@Component({
  selector: 'auth-usuarios-usuario-estatus',
  templateUrl: './estatus.component.html',
  styleUrls: ['./estatus.component.scss'],
})
export class AuthUsuariosUsuarioEstatusComponent {
  displayModal = true
  error = null
  procesando = false
  exito = false
  usuarioId!: number
  @Input() data: IUsuarioEstatus
  @Output() cerrar: EventEmitter<IUsuarioEstatus | null> = new EventEmitter()

  constructor(
    private usuariosUsuarioEstatusService: UsuariosUsuarioEstatusService,
    private routeActived: ActivatedRoute,
  ) {
    this.usuarioId = this.routeActived.snapshot.params.usuarioId
  }

  finalizar(data: IUsuarioEstatus | null = null): void {
    this.displayModal = false
    this.cerrar.emit(data)
  }

  guardar(): void {
    if (this.procesando) {
      return
    }

    this.procesando = true
    this.data.activo = !this.data.activo

    this.usuariosUsuarioEstatusService
      .put(this.data.activo, this.usuarioId)
      .pipe(
        map((response) => {
          this.exito = true
          return response
        }),
        catchError((error) => {
          this.error = error
          this.data.activo = !this.data.activo
          return of(error)
        }),
      )
      .subscribe(() => (this.procesando = false))
  }
}
