export class TareasPendientesDto {
  id!: number
  tarea!: TareaPendienteTareaDto
  prospecto!: TareaPendienteProspectoDto
}

export class TareaPendienteTareaDto {
  id!: number
  descripcion!: string
  capturaFecha!: Date
  capturaUsuarioId!: number
  fecha!: Date
  fechaRecordatorio!: Date
}

export class TareaPendienteProspectoDto {
  id!: number
  nombre!: string
  foto!: string
}
