import { IsNumber, IsOptional, IsString } from 'class-validator'

export class CatalogoEstadoDto {
  @IsNumber()
  id?: number

  @IsString()
  descripcion!: string

  @IsOptional()
  @IsNumber()
  paisId?: number
}
