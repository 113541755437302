import { faFacebookSquare, faProductHunt } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faSquare, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import {
  faAd,
  faAddressBook,
  faBoxes,
  faCartPlus,
  faCashRegister,
  faCheckCircle,
  faCircle,
  faClipboard,
  faCubes,
  faDatabase,
  faDollarSign,
  faExclamationTriangle,
  faEye,
  faFileContract,
  faFileInvoice,
  faFileInvoiceDollar,
  faHandHoldingUsd,
  faHome,
  faInbox,
  faList,
  faMinusSquare,
  faMoneyCheck,
  faObjectGroup,
  faPencilAlt,
  faPeopleArrows,
  faPlusCircle,
  faPrint,
  faPuzzlePiece,
  faShoppingCart,
  faStore,
  faSwatchbook,
  faTags,
  faTrash,
  faUserCircle,
  faUserFriends,
  faUserLock,
  faUserPlus,
  faUsers,
  faWallet,
} from '@fortawesome/free-solid-svg-icons'
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog'

export const iconsModal = {
  delete: faTrashAlt,
  exito: faCheckCircle,
  advertencia: faExclamationTriangle,
}
export const ventasIcons = {
  cross: faTrashAlt,
  minusSquare: faMinusSquare,
  checkSquare: faCheckSquare,
  square: faSquare,
  print: faPrint,
  eye: faEye,
}

export const clienteIcons = {
  basura: faTrash,
  checkSquare: faCheckSquare,
  square: faSquare,
}

export const iconsDataBase = {
  inbox: faInbox,
  aplicaciones: faFacebookSquare,
  contactos: faAddressBook,
  publico: faPeopleArrows,
  campanias: faAd,
  agentes: faUserFriends,
  contrato: faFileContract,
  arqueoCaja: faFileInvoiceDollar,
  corteHistorico: faMoneyCheck,
  // usuarios: faUserCog,
  puzzle: faPuzzlePiece,
  agregarUsuario: faUserPlus,
  cartera: faWallet,
  home: faHome,
  departamentos: faCubes,
  familias: faPeopleArrows,
  grupos: faObjectGroup,
  moduloProductos: faProductHunt,
  visualizacionProdustos: faProductHunt,
  promociones: faTags,
  catalogoMayoreo: faDollarSign,
  catalogos: faSwatchbook,
  sucursales: faStore,
  add: faPlusCircle,
  gestionarExistencia: faDatabase,
  permisosSucursal: faUserLock,
  inventarios: faBoxes,
  clientes: faUsers,
  listaclientes: faUsers,
  proveedores: faPeopleArrows,
  usuarios: faUserCircle,
  cuentasPorCobrar: faHandHoldingUsd,
  listaProveedores: faPeopleArrows,
  compras: faShoppingCart,
  cajascortes: faDollarSign,
  listaCajas: faCashRegister,
  cortesActivos: faClipboard,
  ventas: faTags,
  misVentas: faFileInvoiceDollar,
  nuevaVenta: faCartPlus,
  solicitudes: faList,
  recepcionPedidos: faFileInvoice,
  entradaMercancia: faClipboard,
}

export const iconsRecepcionPedidos = {
  editar: faPencilAlt,
}
export const iconsCajas = {
  configuracion: faCog,
  basura: faTrash,
  activo: faCircle,
}
export const iconoCortesActivos = {
  retiros: faHandHoldingUsd,
  liquidacion: faFileInvoiceDollar,
}
