export const meses = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
]

export const meses3Dig = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']

export const formatoFecha = (fecha: string | Date) => {
  const dia = new Date(fecha)
  let fechaFormateada = `${dia.getDate().toString().padStart(2, '0')} de ${meses[dia.getMonth()]}`
  if (dia.getFullYear() != new Date().getFullYear()) {
    fechaFormateada = `${fechaFormateada} del ${dia.getFullYear()}`
  }
  return fechaFormateada
}

export const formatoDateTime = (fecha: string | Date): string => {
  const dia = new Date(fecha)
  return `${dia.getDate().toString().padStart(2, '0')} de ${meses3Dig[dia.getMonth()]}${getYear(
    dia.getFullYear(),
  )} ${getTime(fecha)}`
}

const getYear = (year: number): string => {
  if (year === new Date().getFullYear()) {
    return ''
  }
  return ` del ${year}`
}

const getTime = (fecha: string | Date): string => {
  const dia = new Date(fecha)
  let a = 'AM'
  let hora = dia.getHours()
  if (hora > 12) {
    a = 'PM'
    hora = hora - 12
  }
  return `${hora.toString().padStart(2, '0')}:${dia.getMinutes().toString().padStart(2, '0')} ${a}`
}

export const formatoFechaCorta = (fecha: string | Date): string => {
  const dia = new Date(fecha)
  return `${dia.getDate().toString().padStart(2, '0')}-${meses3Dig[dia.getMonth()]}-${dia.getFullYear()}`
}

export const formatoHora = (fecha: string) => {
  const hora = new Date(+fecha)
  return `${hora.getHours().toString().padStart(2, '0')}:${hora.getMinutes().toString().padStart(2, '0')}`
}

export const formatoFechaNumero = (dia: Date) => {
  dia = new Date(dia)
  return `${dia.getFullYear()}-${(dia.getMonth() + 1).toString().padStart(2, '0')}-${dia
    .getDate()
    .toString()
    .padStart(2, '0')}`
}
