import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IUsuarioCompleto } from '../../types'

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  constructor(@Inject(HttpClient) private http: HttpClient) {}

  get(usuarioId: number): Observable<IUsuarioCompleto> {
    return this.http.get<any>(`/api/auth/usuarios/usuario/${usuarioId}`)
  }

  updateInformation(data: any): Observable<any> {
    const formData = new FormData()
    formData.append('master', data.master)
    formData.append('duraciones', data.duraciones)
    formData.append('alias', data.alias)
    return this.http.post('', formData)
  }
}
