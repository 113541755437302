<ng-container *ngFor="let item of navegacion$ | async">
  <auth-sesion-current-menu-item *ngIf="item.url && item.icon; else headerTemplate" [url]="item.url">
    <fa-icon *ngIf="item.icon" [icon]="iconsDataBase[item.icon]" size="sm" slot="icon"></fa-icon>
    <div class="only-expanded">{{ item.nombre }}</div>
  </auth-sesion-current-menu-item>
  <ng-template #headerTemplate>
    <div class="uppercase opacity-60 text-sm p-3 truncate select-none">
      <div class="only-expanded">{{ item.nombre }}</div>
    </div>
  </ng-template>
</ng-container>
