import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IAgrupacionesUsuarioPermisos, IUsuarioPermisos } from '../../../../../../types'

@Injectable({
  providedIn: 'root',
})
export class AuthUsuariosUsuarioCuentaPermisosService {
  constructor(@Inject(HttpClient) private http: HttpClient) {}

  get(usuarioId: number): Observable<IUsuarioPermisos> {
    return this.http.get<IUsuarioPermisos>(`/api/auth/usuarios/usuario/${usuarioId}/cuenta/permisos`)
  }

  put(data: IAgrupacionesUsuarioPermisos, usuarioId: number): Observable<IUsuarioPermisos> {
    return this.http.put<IUsuarioPermisos>(`/api/auth/usuarios/usuario/${usuarioId}/cuenta/permisos`, data)
  }
}
