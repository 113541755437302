import { Type } from 'class-transformer'
import { IsBoolean, IsNumberString } from 'class-validator'

export class agenteDto {
  id?: number
  activo!: boolean
  master!: boolean
  ultimaConexion!: Date
  usuarioId!: number
}
export class AgenteUsuarioParamsDto {
  @IsNumberString()
  usuarioId!: number
}

export class AgenteUpdateDto {
  @IsBoolean()
  @Type(() => Boolean)
  master!: boolean
}
export class AgentesInterfaceDto {
  id!: number | null
  master!: boolean
  nombre!: string
  usuarioId?: number
  busqueda?: string
}
export class UsuarioInterfaceDto {
  id!: number | null
  nombre!: string
}
