import { IsBoolean, IsDateString, IsNumber, IsOptional, IsString, MaxLength, ValidateNested } from 'class-validator'

export class CatalogoDto {
  @IsNumber()
  id!: number

  @IsOptional()
  @IsString()
  descripcion?: string
}

export class CompraDetalleDto {
  @IsNumber()
  compraId!: number
  @IsNumber()
  produtoId!: number
  @IsNumber()
  importe!: number
  @IsNumber()
  costo!: number
  @IsNumber()
  cantidad!: number
}
export class CatalogoFormaPagoDto extends CatalogoDto {}

export class CatalogoMetodoPagoDto extends CatalogoDto {}

export class CatalogoMonedaDto extends CatalogoDto {}

export class CatalogoTipoCambioDto extends CatalogoDto {}

export class CatalogoUsuarioDto extends CatalogoDto {}

export class CatalogoSucursalDto extends CatalogoDto {}

export class CatalogoProveedorDto extends CatalogoDto {}

export class CatalogoMotivoDto extends CatalogoDto {}

export class CompraDto {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsString()
  @MaxLength(32)
  folioFiscal!: string

  @IsString()
  folio!: string

  @ValidateNested()
  sucursal!: CatalogoSucursalDto

  @IsNumber()
  subtotal!: number

  @IsNumber()
  descuento!: number

  @IsNumber()
  impuestos!: number

  @ValidateNested()
  formaPago!: CatalogoFormaPagoDto

  @ValidateNested()
  metodoPago!: CatalogoMetodoPagoDto

  @ValidateNested()
  moneda!: CatalogoMonedaDto

  @IsNumber()
  tipoCambio!: number

  @IsBoolean()
  orden!: boolean

  @IsBoolean()
  pagada!: boolean

  @IsOptional()
  @IsDateString()
  fechaPagada?: Date

  @IsString()
  nota!: string

  @IsOptional()
  @IsDateString()
  fecha?: Date

  @IsOptional()
  @ValidateNested()
  proveedor!: CatalogoProveedorDto | null

  @ValidateNested()
  detalles!: CompraDetalleDto[] | null

  @IsOptional()
  @ValidateNested()
  capturaUsuario?: CatalogoUsuarioDto

  @IsOptional()
  @IsDateString()
  capturaFecha?: Date | null

  @IsOptional()
  @ValidateNested()
  cancelacionUsuario?: CatalogoUsuarioDto

  @IsOptional()
  @IsDateString()
  cancelacionFecha?: Date | null
}
