<div class="header">
  <div class="toolbar-container">
    <div class="toolbar-container-header">
      <div class="toolbar-header">
        <div class="__header-title typo-title">Reestablecer</div>
      </div>
    </div>
  </div>
</div>
<div style="margin: 0 auto; max-width: 960px">
  <div class="padding-md">
    <h1 class="typo-display margin-md text-align-center">Reestablecer contraseña</h1>
  </div>
  <form #form="ngForm" class="padding-md reestablecer" *ngIf="!completado" autocomplete="off" (submit)="reestablecer()">
    <p class="--margin-lg">
      ¿Olvidó su contraseña? Ingrese el correo electrónico asociado con su nombre de usuario y le enviaremos las
      instrucciones para reestablecerla.
    </p>
    <div class="at-fieldset margin-md">
      <div class="at-control">
        <input
          class="p-inputtext"
          type="email"
          required
          placeholder="email@ejemplo.com"
          pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}"
          name="email"
          #email="ngModel"
          [(ngModel)]="data.email"
        />
      </div>

      <ul class="at-control_messages" *ngIf="email.errors">
        <li *ngIf="email.errors.required && email.touched">Email requerido</li>
        <li *ngIf="email.errors.pattern && email.touched">Email en formato incorrecto</li>
      </ul>
    </div>
    <div class="margin-md --text-error" *ngIf="error">
      {{ error.message }}
    </div>
    <div class="at-item at-item--reverse">
      <button
        class="p-button p-button-raised"
        type="submit"
        aria-disabled="true"
        [ngClass]="{ 'p-disabled': form.invalid || procesando }"
        [disabled]="form.invalid || procesando"
      >
        Enviar email
      </button>
    </div>
  </form>
  <section class="padding-md reestablecer" *ngIf="completado">
    <p class="margin-md">
      Revise la bandeja de entrada de su email. Si no ve el correo en los siguientes minutos, revise su bandeja de
      correo no deseado.
    </p>
    <br /><br />
    <div class="text-align-right margin-md">
      <a class="p-button p-button-raised" routerLink="/sesion/login"> Regresar a inicio </a>
    </div>
  </section>
</div>
