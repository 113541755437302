import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IUsuairoEmpresaModulos } from '../../../types'

@Injectable({
  providedIn: 'root',
})
export class UsuarioPermisosModulosService {
  constructor(@Inject(HttpClient) private $http: HttpClient) {}

  get(usuarioId: number): Observable<IUsuairoEmpresaModulos> {
    return this.$http.get<IUsuairoEmpresaModulos>(`/api/usuario/${usuarioId}/permisos/modulos`)
  }

  post(usuarioId: number, data: IUsuairoEmpresaModulos): Observable<IUsuairoEmpresaModulos> {
    return this.$http.post<IUsuairoEmpresaModulos>(`/api/usuario/${usuarioId}/permisos/modulos`, data)
  }
}
