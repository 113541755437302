import { Type } from 'class-transformer'
import { IsOptional, IsString, ValidateNested } from 'class-validator'

export class EcommerceConfiguracionContenidoDto {
  @IsOptional()
  @IsString()
  nivel1?: string

  @IsOptional()
  @IsString()
  nivel2?: string
}

export class EcommerceConfiguracionDto {
  @ValidateNested()
  @Type(() => EcommerceConfiguracionContenidoDto)
  configuracion!: EcommerceConfiguracionContenidoDto
}
