import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Observable, throwError } from 'rxjs'
import { catchError, finalize, map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class AutorizacionService implements HttpInterceptor {
  constructor(private router: Router, private activedRoute: ActivatedRoute) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = null

    const ngToken = localStorage.getItem('ng-token') || ''
    const ngCuenta = localStorage.getItem('ng-cuenta') || ''
    const headers = new HttpHeaders({
      Authorization: `Barrer ${ngToken}`,
      Account: ngCuenta,
      //TODO: obtener de algún servicio
      Company: '1',
    })
    request = req.clone({
      headers,
      // url: req.url,
    })

    return next.handle(request).pipe(
      map((response: HttpResponse<any>) => {
        return response
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 406) {
          // no aceptable
          // podría utilizarse para peticiones solicitadas de usuarios que no le corresponden
          // exceptando obvamente desde la api las de dominios de empresa que se pasarán siempre
          // validando solamente si el usuario tiene permiso al recurso solicitado y/o es público
          // componente solicitará el current en base al usuario y recargará la página solicitada
          // remplazando los valores o mandandola a la ventana principal por malora (mejor opción)
          //
          this.router.navigateByUrl('/session/current')
        }

        if (error.status === 403) {
          this.router.navigateByUrl('/authorization')
        }

        if (error.status === 401) {
          this.router.navigate(['/session/login'], {
            queryParams: { redirect: this.router.url },
          })
        }

        return throwError(error)
      }),
      finalize(() => {
        // al finalizar de armar la petición HTTP
      }),
    )
  }
}

export const InterceptorProvider = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AutorizacionService,
    multi: true,
  },
]
