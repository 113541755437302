import { IsBoolean, IsString } from 'class-validator'

export class CashRegisterCajaDto {
  id!: any
  @IsString()
  nombre!: string
  @IsString()
  token!: string
  @IsBoolean()
  master!: boolean
  @IsBoolean()
  activo!: boolean
}
