import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class AuthSesionPermisosService {
  constructor(/*private store: Store<{ data: any }>*/) {}
  // cargar$(data: ISessionModulosPermiso[]): void {
  //   this.store.dispatch(permisosAction.cargarPermisos({ data }))
  // }

  // obtener$(): Observable<ISessionModulosPermiso[]> {
  //   return this.store.select(permisosSelector.get).pipe()
  // }
}
