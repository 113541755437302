import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { mergeMap, delay, retryWhen } from 'rxjs/operators'
import { NETWORK_CONFIG } from '../constants/network-configuration'
import { NetworkModuleConfig } from '../interfaces/network-configuration'

@Injectable({ providedIn: 'root' })
export class RetryInterceptor implements HttpInterceptor {
  readonly retryAttempts = this.configuration['app.network.retry.attempts']
  readonly retryDelay = this.configuration['app.network.retry.delay']
  readonly retryWhen = this.configuration['app.network.retry.when']

  constructor(@Inject(NETWORK_CONFIG) private configuration: NetworkModuleConfig) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen((errors: Observable<HttpErrorResponse>) =>
        errors.pipe(
          mergeMap((error, index) => {
            if (index < this.retryAttempts && this.retryWhen(error)) {
              return of(error).pipe(delay(this.retryDelay))
            }

            throw error
          }),
        ),
      ),
    )
  }
}
