import { Type } from 'class-transformer'
import { IsDate, IsNumber, IsOptional, IsString } from 'class-validator'
import { CatalogoMotivoDto } from '@decet/pos-shared'
import 'reflect-metadata'

export class MovimientosDto {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsDate()
  @Type(() => Date)
  capturaFecha!: Date

  @IsNumber()
  cantidad!: number

  @IsString()
  usuario!: string

  @IsString()
  producto!: string

  //TODO:VALIDATED NESTED

  motivo!: CatalogoMotivoDto
}
