import { Component, EventEmitter, Input, Output } from '@angular/core'
import { IAgrupacionUsuarioPermisos } from '../../../../../../../../../types'

@Component({
  selector: 'auth-usuarios-usuario-dominio-permisos-lista-agrupacion',
  templateUrl: './agrupacion.component.html',
  styleUrls: ['./agrupacion.component.scss'],
})
export class AgrupacionComponent {
  todosSeleccionados = true
  @Input() item!: IAgrupacionUsuarioPermisos
  @Output() selected = new EventEmitter<void>()

  seleccionar(): void {
    this.selected.emit()
  }
}
