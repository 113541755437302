import { IsBoolean, IsDateString, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'

export class CorteDto {
  @IsOptional()
  cajaId!: number
  sucursalId!: number
  usuarioId!: number
  fondo!: number
  metodosDePago!: number[]
  formasDePago!: number[]
  @IsOptional()
  token!: string
}

export class CorteAgrupadosInterface {
  agrupacion!: string
  data!: CashRegisterCorteDto[]
}

class CashRegisterCorteCajaDto {
  @IsNumber()
  id!: number

  @IsOptional()
  @IsString()
  descripcion!: string
}

class CashRegisterCorteSucursalDto {
  id!: number
  descripcion!: string
}

class CashRegisterCorteUsuarioDto {
  @IsNumber()
  id!: number

  @IsOptional()
  @IsString()
  nombre!: string

  @IsOptional()
  @IsString()
  apellidos!: string
}

class CashRegisterCorteVentaDto {
  @IsNumber()
  total!: number
}

export class CashRegisterCorteDto {
  @IsNumber()
  id!: any

  @ValidateNested()
  sucursal!: CashRegisterCorteSucursalDto

  @ValidateNested()
  caja!: CashRegisterCorteCajaDto

  @ValidateNested()
  usuario!: CashRegisterCorteUsuarioDto

  @ValidateNested()
  ventas!: CashRegisterCorteVentaDto

  @IsBoolean()
  finalizado!: boolean
}
export class CashRegisterCorteLiquidadoDto {
  @IsNumber()
  id!: any

  @ValidateNested()
  sucursal!: CashRegisterCorteSucursalDto

  @ValidateNested()
  caja!: CashRegisterCorteCajaDto

  @ValidateNested()
  usuario!: CashRegisterCorteUsuarioDto

  @ValidateNested()
  ventas!: CashRegisterCorteVentaDto

  @IsNumber()
  faltante!: number

  @IsNumber()
  sobrante!: number
}

export class CashRegisterCorteRetiroDto {
  @IsOptional()
  @IsNumber()
  id!: number
  @IsNumber()
  cantidad!: number
  @IsDateString()
  fecha!: Date | string
}

export class CashRegisterCorteConRetirosDto extends CashRegisterCorteDto {
  retiros!: CashRegisterCorteRetiroDto[]
}

export class LiquidacionVentasFormaPagoMetodoPagoDto {
  @IsNumber()
  id!: number
  @IsString()
  descripcion!: string
}

export class LiquidacionVentasFormaPagoFormaPagoDto {
  @IsNumber()
  id!: number

  @IsOptional()
  @IsString()
  descripcion!: string

  @IsOptional()
  @ValidateNested()
  metodoPago!: LiquidacionVentasFormaPagoMetodoPagoDto
}

export class LiquidacionVentasFormaPagoDto {
  @ValidateNested()
  formaPago!: LiquidacionVentasFormaPagoFormaPagoDto
  @IsNumber()
  total!: number
}
