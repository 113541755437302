import { Component, Input } from '@angular/core'
import { BehaviorSubject, filter, map, share, switchMap } from 'rxjs'
import { iconsDataBase } from '@decet/pos-shared'
import { AuthSesionModuloService } from '../../../usuarios/apis/modulo.service'
import { ListItem } from '../menu-item/menu-item.component'

@Component({
  selector: 'auth-sesion-current-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class AuthSesionCurrentMenuComponent {
  @Input()
  public set seccion(seccion: string) {
    this.seccion$.next(seccion)
  }

  private seccion$ = new BehaviorSubject('')

  public navegacion$ = this.seccion$.pipe(
    filter((seccion) => !!seccion),
    switchMap((seccion) => this.moduloService.get(seccion)),
    map(({ nombre, modulos }) => {
      const navegacion: ListItem[] = [{ nombre: 'Dashboard', icon: 'home', url: [] }, { nombre }]

      for (const modulo of modulos) {
        navegacion.push({
          nombre: modulo.descripcion,
          icon: modulo.icon,
          url: modulo.url.replace(/^\//, '').split('/'),
        })
      }

      return navegacion
    }),
    share({ resetOnRefCountZero: false }),
  )

  public iconsDataBase = iconsDataBase

  constructor(private moduloService: AuthSesionModuloService) {}
}
