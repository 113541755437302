import { Type } from 'class-transformer'
import { IsDate, IsNumber, IsString, ValidateNested } from 'class-validator'

export class ReporteProveedorDto {
  @IsNumber()
  id!: number

  @IsString()
  nombre!: string
}
export class ReporteDetalleCompraDto {
  @IsNumber()
  cantidad!: number

  @IsNumber()
  costo!: number

  @IsNumber()
  importe!: number

  @IsString()
  producto!: string
}
export class ReporteCompraDto {
  @IsNumber()
  id!: number

  @IsString()
  folio!: string

  @ValidateNested()
  @Type(() => ReporteProveedorDto)
  proveedor!: ReporteProveedorDto

  @IsNumber()
  sucursalId!: number

  @IsNumber()
  impuesto!: number

  @IsNumber()
  subtotal!: number

  @IsNumber()
  total!: number

  @ValidateNested()
  @Type(() => ReporteDetalleCompraDto)
  detalle!: ReporteDetalleCompraDto[]

  @IsDate()
  cancelacionFecha!: Date

  @IsDate()
  fecha!: Date
}
