import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatSidenavModule } from '@angular/material/sidenav'
import { RouterModule } from '@angular/router'
import { AuthSesionModule } from '@inmueble/auth'
import { DecetCRMComponent } from './component/crm.component'

@NgModule({
  declarations: [DecetCRMComponent],
  imports: [CommonModule, AuthSesionModule, RouterModule, MatSidenavModule],
  exports: [DecetCRMComponent],
})
export class DecetCrmModule {}
