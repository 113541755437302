import { HttpClient } from '@angular/common/http'
import { Injectable, Inject } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class VerificarSesionAliasService {
  constructor(@Inject(HttpClient) private http: HttpClient) {}

  get(username: string, usuarioId: number): Observable<boolean> {
    return this.http.get<boolean>(`/api/auth/usuario/${usuarioId}/sesion/username`, {
      params: { username },
    })
  }
}
