import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IEmpresas } from '../../../types'

@Injectable({
  providedIn: 'root',
})
export class CatalogoEmpresasService {
  constructor(@Inject(HttpClient) private http: HttpClient) {}

  get(): Observable<IEmpresas> {
    return this.http.get<any>(`/api/importar-xml/catalogos/empresas`)
  }
}
