import { Component, OnInit, Input } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { UsuarioService } from '../../../../apis/usuario/usuario.service'
import { IAuthUsuariosUsuarioInformacion } from '../../../../types'

@Component({
  selector: 'auth-usuarios-usuario.invitacion',
  templateUrl: './invitacion.component.html',
  styleUrls: ['./invitacion.component.scss'],
})
export class AuthUsuariosUsuarioInvitacionComponent implements OnInit {
  @Input() usuarioId: number
  data: IAuthUsuariosUsuarioInformacion
  enviar: boolean
  promise: Promise<void | boolean> = null

  constructor(private usuarioService: UsuarioService, private actRoute: ActivatedRoute) {
    this.usuarioId = this.actRoute.snapshot.params.usuarioId
    this.enviar = false
    this.data = {
      apellidos: '',
      nombre: '',
      numeroCelular: '',
      correo: '',
    }
  }

  ngOnInit(): void {
    this.promise = this.usuarioService
      .get(this.usuarioId)
      .toPromise()
      .then((data) => {
        this.data = data
      })
  }

  guardar(): void {
    this.enviar = true
  }

  cerrar(event): void {
    this.enviar = false
    if (event) {
      console.log('agrego', event)
      // this.router.navigateByUrl('/empresas').then();
    }
  }
}
