<form class="card rounded mt-4 p-4" name="form" #form="ngForm" autocomplete="off" (ngSubmit)="guardar()">
  <div class="grid gap-2 grid-cols-12">
    <div class="p-fluid col-span-6">
      <div class="p-field text-gris-obscuro">
        <label class="text-sm px-1">Nombre</label>
        <input
          class="p-inputtext"
          placeholder="Nombre"
          required
          (change)="copia.nombre = copia.nombre.trim()"
          (ngModelChange)="finalizado = false"
          [disabled]="!activo"
          #nombre="ngModel"
          [(ngModel)]="copia.nombre"
          name="nombre"
          type="text"
        />
        <div *ngIf="nombre.touched && nombre.errors">
          <span class="text-xs ml-1 text-red-800" *ngIf="nombre.errors.required">El nombre es requerido</span>
        </div>
      </div>
    </div>
    <div class="p-fluid col-span-6">
      <div class="p-field text-gris-obscuro">
        <label class="text-sm px-1">Apellidos</label>
        <input
          class="p-inputtext"
          placeholder="Apellidos"
          #apellidos="ngModel"
          required
          (ngModelChange)="finalizado = false"
          (change)="copia.apellidos = copia.apellidos.trim()"
          [disabled]="!activo"
          [(ngModel)]="copia.apellidos"
          name="apellidos"
          type="text"
        />
        <div *ngIf="apellidos.touched && apellidos.errors">
          <span class="text-xs ml-1 text-red-800" *ngIf="apellidos.errors.required">El apellido es requerido</span>
        </div>
      </div>
    </div>
    <div class="p-fluid col-span-6">
      <div class="p-field text-gris-obscuro">
        <label class="text-sm px-1">Correo</label>
        <input
          class="p-inputtext"
          type="text"
          #correo="ngModel"
          (ngModelChange)="finalizado = false"
          [(ngModel)]="copia.correo"
          required
          placeholder="Correo"
          (change)="verificarInfCorreo()"
          name="correo"
          [disabled]="!activo"
        />
        <div class="at-control_messages" *ngIf="correoInfExiste && copia.correo">
          <span class="text-xs ml-1 text-red-800">Correo ya registrado</span>
        </div>
        <div *ngIf="correo.touched && correo.errors">
          <span class="text-xs ml-1 text-red-800" *ngIf="correo.errors.required">El correo es requerido</span>
        </div>
      </div>
    </div>
    <div class="p-fluid col-span-6">
      <div class="p-field text-gris-obscuro">
        <label class="text-sm px-1">Número Celular</label>
        <input
          class="p-inputtext"
          placeholder="Celular"
          minlength="10"
          (ngModelChange)="finalizado = false"
          maxlength="10"
          required
          (change)="copia.numeroCelular = copia.numeroCelular.trim()"
          [disabled]="!activo"
          #numeroCelular="ngModel"
          [(ngModel)]="copia.numeroCelular"
          name="numeroCelular"
          pKeyFilter="pint"
          type="text"
        />
        <div *ngIf="numeroCelular.touched && numeroCelular.errors">
          <span class="text-xs ml-1 text-red-800" *ngIf="numeroCelular.errors.required"
            >El número celular es requerido</span
          >
        </div>
      </div>
    </div>
  </div>

  <div class="--text-error" *ngIf="error">{{ error }}</div>
  <div *ngIf="form.dirty && activo && !finalizado" class="mt-2">
    <button class="button" [ngClass]="{ 'p-disabled': form.invalid }" type="submit" [disabled]="form.invalid">
      Guardar
    </button>
  </div>
</form>
<p-toast position="bottom-right"></p-toast>
