export const randomDatestring32 = () => {
  let s = Date.now().toString()
  const randomchar = () => {
    const n = Math.floor(Math.random() * 62)
    if (n < 10) return n //1-10
    if (n < 36) return String.fromCharCode(n + 55) //A-Z
    return String.fromCharCode(n + 61)
  }
  while (s.length < 32) s += randomchar()
  return s.toUpperCase()
}
export const getIdRamdon = () => {
  return Array(39)
    .fill(null)
    .map(() => Math.round(Math.random() * 24).toString(24))
    .join('')
}
