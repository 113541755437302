import { IsNumber, IsOptional, IsString } from 'class-validator'

export class TraspasosProductoDto {
  @IsNumber()
  id!: number

  @IsString()
  nombre!: string

  @IsString()
  existencia!: number

  @IsString()
  codigo!: string

  @IsOptional()
  @IsNumber()
  cantidad?: number
}
