import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { SesionService } from '../../../../../../sesion/browser/src/lib/services/sesion.service'

@Component({
  selector: 'auth-sesion-current-2',
  templateUrl: './current.component.html',
})
export class AuthSesionCurrentComponent {
  constructor(private authService: SesionService, private router: Router) {}
}
