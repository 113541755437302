import { Component, Input } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { iconsDataBase } from '@decet/pos-shared'

export type IconsDataBase = typeof iconsDataBase

export type IconsDatabaseKey = keyof IconsDataBase

export interface ListItem {
  nombre: string
  icon?: string
  url?: string[]
}

@Component({
  selector: 'auth-sesion-current-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class AuthSesionCurrentMenuItemComponent {
  public urlSegments?: string[] = []

  private _url?: string[] = []

  @Input()
  set url(url: string[] | undefined) {
    this._url = url

    if (!Array.isArray(url)) {
      this.urlSegments = undefined
      return
    }

    this.urlSegments = ['/', ...([this.routeActived.snapshot.params.workspace] ?? []), ...url]
  }

  get url() {
    return this._url
  }

  constructor(private routeActived: ActivatedRoute) {}
}
