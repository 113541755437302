<div class="card hoverable rounded">
  <div class="h-16 flex p-2 px-4 items-center">
    <div class="flex-grow flex-col">
      <div>{{ data.nombre }}</div>
      <div class="text-sm">{{ data.correo }}</div>
    </div>
    <div class="icon">
      <i class="pi pi-user font-bold" [ngClass]="{ 'text-green-700': data.activo, 'text-gray-500': !data.activo }"></i>
    </div>
  </div>
</div>
