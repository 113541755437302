export class MensajeRecibidoDto {
  plataforma!: any
  cuenta!: any
  timestamp!: number
  messages!: MensajeRecibidoContenidoDto
  contact!: MensajeRecibidoContactoDto
}

export class MensajeRecibidoContenidoDto {
  id?: number | string
  text?: any
  video?: any
  image?: any
  document?: any
}
export class MensajeRecibidoContactoDto {
  nombre!: string
  identificador!: string
}
