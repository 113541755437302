import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IAuthUsuariosUsuarioInformacion } from '../../types'

@Injectable({
  providedIn: 'root',
})
export class AuthUsuariosUsuarioInvitacionService {
  constructor(@Inject(HttpClient) private http: HttpClient) {}

  post(data: IAuthUsuariosUsuarioInformacion): Observable<object> {
    return this.http.post(`/api/auth/usuarios/usuario/${data.id}/invitacion`, data)
  }
}
