import { Type } from 'class-transformer'
import { IsBoolean, IsEmail, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import 'reflect-metadata'
import { KardexDto } from '@decet/kardex-shared'

export class CrmCuentaDto {
  id!: any

  @IsNumber()
  @Type(() => Number)
  plataformaId!: number

  @IsString()
  descripcion!: string

  @Type(() => Boolean)
  @IsBoolean()
  privada!: boolean

  @IsOptional()
  apikey?: string

  @IsOptional()
  identificador?: string

  @IsOptional()
  accessToken?: string

  @IsString()
  alias!: string

  agentes: any | { id: number }[]

  configuracion!: CrmCuentaConfiguracionDto
}

export class CrmCuentaConfiguracionDto {
  asignacion!: string
  expiracion!: number
  expiracionAsignacion!: number

  @IsOptional()
  indexContinuidad?: number
  activo!: boolean
  @IsOptional()
  proveedor?: string
}

export class SistemaMensajeria {
  @IsNumber()
  @Type(() => Number)
  plataformaId!: number

  @IsString()
  nombre!: string
  @IsString()
  identificador!: string
  @IsString()
  dominio!: string
  @IsString()
  token!: string

  @IsOptional()
  @IsString()
  nuevoIdentificador?: string
}

export class CrmCuentaProspectoGet {
  cuenta!: any

  plataforma!: any

  prospecto!: any

  contenido!: CrmCuentaProspectoContenido

  contenidoId!: number
}

export class CrmCuentaProspectoDto {
  cuenta!: any

  plataforma!: any
}

export class ContactoCorreoDto {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsEmail()
  correo!: string
}

export class ContactoDto {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsOptional()
  @ValidateNested()
  kardex?: KardexDto

  @IsOptional()
  @IsString()
  foto!: string

  @IsString()
  nombre!: string

  @IsOptional()
  @IsString()
  apellidoPaterno?: string

  @IsOptional()
  @IsString()
  apellidoMaterno?: string

  @ValidateNested({ each: true })
  @Type(() => ProspectoTelefonoDto)
  telefonos!: ProspectoTelefonoDto[]

  @ValidateNested({ each: true })
  @Type(() => ContactoCorreoDto)
  correos!: ContactoCorreoDto[]

  nacimiento?: Date

  @IsOptional()
  @IsString()
  idioma!: string

  @IsOptional()
  @IsString()
  nota?: string

  //reconsiderar identificador
  identificador?: string[]

  etiquetas?: number[]

  @IsOptional()
  agentes?: number[]

  @IsOptional()
  @IsBoolean()
  destacado?: boolean

  @IsOptional()
  @IsString()
  estatus?: string
}

export class CrmCuentaProspectoPost {
  cuenta!: CrmCuentaProspectoCuenta

  @IsNumber()
  plataformaId!: number

  @ValidateNested()
  prospecto!: ContactoDto

  contenido!: CrmCuentaProspectoContenido
}

export class ProspectoTelefonoDto {
  @IsOptional()
  id?: number

  @IsOptional()
  contactoId?: number

  @IsString()
  telefono!: string | null

  @IsString()
  lada!: string

  @IsString()
  codigoPais!: string
}

export class CrmCuentaProspectoCuenta {
  id!: number
  descripcion!: string
  alias?: string
}

export class CrmCuentaProspectoContenido {
  agenteId!: number

  mensaje!: any

  @IsNumber()
  fechaMensaje!: string

  @IsBoolean()
  estatus!: boolean

  masivo?: boolean
  subject?: string
}

export class CrmCuentaConfiguracion {
  @IsNumber()
  cuentaId!: number

  @IsString()
  identificador!: string

  configuracion!: any

  @IsString()
  accessToken!: string
}

export class CrmCuenta {
  @IsNumber()
  dominioId!: number

  @IsNumber()
  plataformaId!: number

  @IsString()
  descripcion!: string

  @IsBoolean()
  privada!: boolean

  @IsString()
  apikey!: string

  @IsNumber()
  empresaId!: number

  alias!: string
}
