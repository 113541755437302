// @index('./lib/**/*.ts', (f) => `export * from '${f.path}'`)
export * from './lib/catalogo-shared'
export * from './lib/ciudad.dto'
export * from './lib/estado.dto'
export * from './lib/exchangerate.dto'
export * from './lib/municipio.dto'
export * from './lib/plazos.dto'
export * from './lib/zona.dto'
export * from './lib/periodicidad.dto'
// @endindex
