<form class="box" [formGroup]="credencialForm" (ngSubmit)="aceptar()">
  <svg class="avatar" xmlns="http://www.w3.org/2000/svg" viewBox="0 -17 256 239">
    <path
      d="M 256 199C 241.42 186.65 201.62 167.93 174 162 165.51 146.91 166.5 142.62 156 143 157.86 135.29 164 130 164 130 164 130 164.43 119 166 112 184.38 102.38 180.14 76.24 174 67 173.71 59.9 177.07 38.91 176.16 34.1 172.6 15.44 170.32 12.29 160 12 148.14 2.57 143.78 0.28 124 0 117.84-0.09 91.69 5.81 82 20 70.55 37.25 78.15 65.95 78.25 72.14 74.04 76.14 75.32 81.86 75.72 87.56 76.48 98.19 80.28 109.16 88 109 88.71 115.62 89.2 124.38 90 130 90.52 133.67 96.8 139.2 98 143 93.63 143.63 91 144 91 144 91 144 87.6 153.81 81 162 61.27 169.93 29 179.8-0 197-0 209.6-0 239-0 239-0 239 256 239 256 239 256 239 256 199 256 199Z"
    ></path>
  </svg>
  <mat-form-field appearance="standard">
    <mat-label>Usuario</mat-label>
    <input
      matInput
      type="text"
      name="username"
      autofocus
      formControlName="username"
      required
      pattern="^[a-zA-Z0-9_.-@]*$"
    />
    <mat-error>Ingrese su usuario</mat-error>
  </mat-form-field>
  <!-- </mat-form-field> -->
  <!-- </mat-form-field> -->
  <mat-form-field appearance="standard">
    <mat-label>Contraseña</mat-label>
    <input matInput type="password" name="password" formControlName="password" required />
    <mat-error>Ingrese su contraseña</mat-error>
  </mat-form-field>
  <div class="my-4">
    <button
      class="uppercase self-center"
      mat-button
      mat-raised-button
      color="primary"
      [disabled]="procesando"
      type="submit"
    >
      Iniciar sesion
    </button>
  </div>
  <a class="text-align-center text-small text-sky-700" routerLink="/sesion/reestablecer"> Olvidé mi contraseña </a>
</form>
