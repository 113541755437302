<nav class="panel" [class.collapsed]="collapsed" [class.hover]="hover" (mouseleave)="hover = false">
  <header class="panel-header">
    <button
      mat-icon-button
      (click)="this.collapsed = !this.collapsed"
      [pTooltip]="this.collapsed ? 'Mostrar menú' : 'Ocultar menú'"
      tooltipPosition="left"
      showDelay="500"
    >
      <fa-icon [icon]="collapsed ? icons.expand : icons.collapse"></fa-icon>
    </button>
    <button
      class="flex-1 h-full rounded truncate text-left"
      matRipple
      [matMenuTriggerFor]="empresasMenu"
      type="button"
      *ngIf="!collapsed || hover"
    >
      {{ (unidadNegocio$ | async)?.descripcion }}
    </button>
    <mat-menu #empresasMenu="matMenu">
      <button mat-menu-item *ngFor="let empresa of empresas$ | async" (click)="actualizarEmpresa(empresa)">
        {{ empresa.descripcion }}
      </button>
    </mat-menu>
  </header>
  <!-- Contenido -->
  <div class="panel-content">
    <auth-sesion-current-menu [seccion]="seccion" (mouseenter)="hover = true"></auth-sesion-current-menu>
  </div>
  <footer class="panel-footer">
    <!-- Workspace -->
    <auth-sesion-current-workspace
      *ngIf="parametros$ | async as parametros"
      [empresaId]="parametros.empresaId"
      [collapsed]="collapsed && !hover"
    ></auth-sesion-current-workspace>
    <!-- <hr class="w-11/12 mx-auto" /> -->
    <!-- Acciones -->
    <div class="iconos">
      <button
        type="button"
        [routerLink]="['/session/finalizar']"
        mat-icon-button
        pTooltip="Cerrar sesión"
        tooltipPosition="top"
        showDelay="500"
      >
        <fa-icon class="w-6 h-6" [icon]="icons.user"></fa-icon>
      </button>
      <button
        type="button"
        mat-icon-button
        pTooltip="Agregar usuarios"
        [routerLink]="['users/usuarios/agregar']"
        tooltipPosition="top"
        showDelay="500"
      >
        <fa-icon class="w-6 h-6" [icon]="icons.users"></fa-icon>
      </button>
      <button
        type="button"
        mat-icon-button
        pTooltip="Configuración"
        (click)="confg()"
        tooltipPosition="top"
        showDelay="500"
      >
        <fa-icon class="w-6 h-6" [icon]="icons.cog"></fa-icon>
      </button>
      <button
        type="button"
        mat-icon-button
        pTooltip="Notificaciones"
        (click)="notif()"
        tooltipPosition="top"
        showDelay="500"
      >
        <fa-icon class="w-6 h-6" [icon]="icons.bell"></fa-icon>
      </button>
      <button type="button" mat-icon-button pTooltip="Buscar" (click)="search()" tooltipPosition="top" showDelay="500">
        <fa-icon class="w-6 h-6" [icon]="icons.search"></fa-icon>
      </button>
    </div>
  </footer>
</nav>
