import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { VerificarService } from '../../../usuarios/apis/usuario/verificar.service'
import { lastValueFrom } from 'rxjs'

@Component({
  selector: 'app-sesion-registrar',
  templateUrl: './registrar.component.html',
  styleUrls: ['./registrar.component.scss'],
})
export class AuthSesionRegistrarComponent {
  completado: boolean
  error: unknown
  procesando: boolean
  data: any
  token: string

  constructor(private verificarService: VerificarService, private rutaActiva: ActivatedRoute) {
    this.data = {
      password: '',
      passwordRepeat: '',
      aceptoTerminosServicio: false,
      token: this.rutaActiva.snapshot.params.token,
    }
  }

  verificar(): void {
    if (this.procesando) {
      return
    }
    if (!this.data.aceptoTerminosServicio) {
      this.error = { message: 'Es necesario aceptar los terminos y sevicio' }
      return
    }
    if (this.data.password !== this.data.passwordRepeat) {
      this.error = { message: 'Las contraseñas no son iguales' }
      return
    }
    this.error = null
    this.procesando = true
    lastValueFrom(this.verificarService.post(this.data))
      .then(() => {
        this.completado = true
        this.procesando = false
      })
      .catch((error) => {
        this.error = error
        if (error.error && error.error.error) {
          this.error = error
          this.procesando = false
        }
      })
  }
}
