import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
  faBell,
  faCog,
  faHome,
  faIndent,
  faOutdent,
  faSearch,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { BehaviorSubject, filter, shareReplay, switchMap } from 'rxjs'
import { SesionService } from '@decet/sesion-browser'
import { AuthSesionEmpresaService } from '../services/empresa.service'
import { AuthSesionEmpresasService } from '../services/empresas.service'
import { UnidadNegocioService } from '../services/unidad-negocio.service'

@Component({
  selector: 'auth-sesion-current',
  templateUrl: './current.component.html',
  styleUrls: ['./current.component.scss'],
})
export class AuthCurrentComponent implements OnInit {
  @Input()
  seccion = ''

  private _collapsed: boolean

  @Output()
  collapsedChange = new EventEmitter<boolean>()

  @Input()
  @HostBinding('class.collapsed')
  get collapsed() {
    return this._collapsed
  }

  set collapsed(collapsed: boolean) {
    if (this._collapsed === collapsed) return

    this._collapsed = collapsed
    if (this._collapsed) this._opened = false
    this.collapsedChange.emit(collapsed)
  }

  private _opened = false

  @Output()
  openedChange = new EventEmitter<boolean>()

  @Input()
  get opened() {
    return !this._collapsed || this._opened
  }

  set opened(opened: boolean) {
    if (!this.collapsed || this._opened === opened) return

    this._opened = opened
    this.openedChange.emit(opened)
  }

  private _hoverDelayInterval: any

  @HostBinding('class.hover')
  get hover() {
    return this.opened
  }

  set hover(hover: boolean) {
    if (!this.collapsed) return

    if (this._hoverDelayInterval) clearInterval(this._hoverDelayInterval)
    this._hoverDelayInterval = setTimeout(() => (this.opened = hover), 500)
  }

  public icons = {
    expand: faIndent,
    collapse: faOutdent,
    home: faHome,
    user: faUser,
    users: faUsers,
    cog: faCog,
    bell: faBell,
    search: faSearch,
  }

  private parametros = new BehaviorSubject<{ workspaceId?: number; empresaId?: number; proyectoId?: number }>({})
  public parametros$ = this.parametros.asObservable()

  //TODO: aqui estamos remplazando la empresa por el proyecto para el caso de oceano palace
  public empresa$ = this.parametros$.pipe(
    filter(({ workspaceId }) => !!workspaceId),
    switchMap(({ workspaceId }) => this.empresaService.get(workspaceId)),
    shareReplay({ refCount: false }),
  )

  public unidadNegocio$ = this.parametros$.pipe(
    filter(({ workspaceId }) => !!workspaceId),
    switchMap(({ proyectoId }) => this.unidadNegocioService.get(proyectoId)),
    shareReplay({ refCount: false }),
  )

  public empresas$ = this.empresasService.get().pipe(shareReplay({ refCount: false }))

  constructor(
    private empresaService: AuthSesionEmpresaService,
    private unidadNegocioService: UnidadNegocioService,
    private empresasService: AuthSesionEmpresasService,
    private activedRoute: ActivatedRoute,
    private sesionService: SesionService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.actualizarParametros()
  }

  actualizarEmpresa(empresa: any) {
    const url: string[] = (this.activedRoute as any)._routerState.snapshot.url.split('/')
    console.log(url, 'url')
    const workspace = url[1].split('-')
    console.log(workspace, 'workspa')
    // this.empresa$ = empresa
    workspace[2] = empresa.id
    url[1] = workspace.join('-')
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([url.join('/')]))
  }

  actualizarParametros() {
    const workspaceParams: string = this.activedRoute.snapshot.params.workspace
    if (!workspaceParams) return
    const [workspaceId, empresaId, proyectoId] = workspaceParams.split('-').map((v) => Number(v))
    this.sesionService.workspaceId = workspaceId
    this.sesionService.empresaId = empresaId
    this.sesionService.proyectoId = proyectoId

    this.parametros.next({ workspaceId, empresaId, proyectoId })
  }

  confg() {
    alert('No tiene configuración habilitada')
  }

  notif() {
    alert('No tiene notificaciones')
  }

  search() {
    alert('No hay búsquedas disponibles')
  }
}
