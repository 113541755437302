import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { finalize, Observable, ReplaySubject, shareReplay, switchMap } from 'rxjs'
import { DefereredPromise, TaskRunner } from '@decet/core/shared/common'
import { NETWORK_CONFIG } from '../constants/network-configuration'
import { NetworkModuleConfig } from '../interfaces/network-configuration'

@Injectable()
export class ConcurrentInterceptor implements HttpInterceptor {
  readonly interval = this.configuration['app.network.concurrent.interval']
  private cache = new Map<string, Observable<HttpEvent<unknown>>>()
  private taskRunner = new TaskRunner()

  constructor(@Inject(NETWORK_CONFIG) private configuration: NetworkModuleConfig) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = request.url
    let cached = this.cache.get(url)

    if (!cached) {
      const { promise, resolve } = new DefereredPromise<void>()
      const subject = new ReplaySubject<void>()

      cached = subject.pipe(
        switchMap(() =>
          next.handle(request).pipe(
            finalize(() => {
              setTimeout(() => this.cache.delete(url), this.interval)
              resolve()
            }),
          ),
        ),
        shareReplay(),
      )

      this.cache.set(url, cached)

      this.taskRunner.add(() => {
        subject.next()

        return promise
      })
    }

    return cached
  }
}
