import { Component, Input } from '@angular/core'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { BehaviorSubject, filter, shareReplay, switchMap } from 'rxjs'
import { AuthSesionWorkspaceService } from '../../services/workspace.service'

@Component({
  selector: 'auth-sesion-current-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss'],
})
export class AuthSesionCurrentWorkspaceComponent {
  @Input()
  public set empresaId(empresaId: number) {
    this.empresaId$.next(empresaId)
  }

  private empresaId$ = new BehaviorSubject<number | undefined>(undefined)

  public workspace$ = this.empresaId$.pipe(
    filter((empresaId) => !!empresaId),
    switchMap((empresaId) => this.workspaceService.get(empresaId)),
    shareReplay({ refCount: false }),
  )

  @Input()
  public collapsed = false

  public icons = {
    collapse: faChevronUp,
    expand: faChevronDown,
  }

  constructor(private workspaceService: AuthSesionWorkspaceService) {}
}
