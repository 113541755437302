import { IsBoolean, IsDateString, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { CatalogoSucursalDto } from '../compras/compras.dto'

export class CatalogoTraspaso {
  @IsNumber()
  id!: number

  @IsOptional()
  @IsString()
  descripcion?: string
}

export class Usuario {
  @IsNumber()
  id!: number

  @IsOptional()
  @IsString()
  username?: string
}

export class ProductoDto extends CatalogoTraspaso {}

export class AlmacenDto extends CatalogoTraspaso {}

export class Usuarios extends Usuario {}

export class TraspasoDto {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsOptional()
  @IsString()
  notas?: string

  @ValidateNested()
  sucursal!: CatalogoSucursalDto

  @ValidateNested()
  almacen!: AlmacenDto

  @IsString()
  fecha!: string | Date

  @IsOptional()
  @ValidateNested()
  usuario?: Usuarios

  @IsOptional()
  @IsBoolean()
  status?: boolean

  @IsOptional()
  @ValidateNested()
  detalle?: TraspasoDetalleDto[]

  // @IsOptional()
  // @ValidateNested()
  // inventario?: InventarioTemporal[]
}

export class RecepcionPedidosCreateDto {
  @IsNumber()
  cantidad!: number

  @IsString()
  fecha!: string | Date

  @IsOptional()
  @ValidateNested()
  traspaso?: TraspasoDto
}

export class TraspasosCreateDto {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsString()
  fecha!: string | null

  @ValidateNested()
  sucursal!: CatalogoSucursalDto

  @ValidateNested()
  almacen!: AlmacenDto

  @IsOptional()
  @IsString()
  notas?: string

  @IsOptional()
  @ValidateNested()
  usuario?: Usuarios

  @IsOptional()
  @ValidateNested()
  detalles?: TraspasoDetalleDto[] | null
}

export class TraspasoDetalleDto {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsString()
  codigo!: string

  @IsOptional()
  @IsNumber()
  cantidad?: number

  @IsOptional()
  @IsNumber()
  cantidadRecibida?: number

  @IsOptional()
  @IsNumber()
  cantidadSolicitada?: number

  @IsOptional()
  @IsString()
  notas?: string

  @IsNumber()
  existencia!: number

  @ValidateNested()
  producto!: { id: number; nombre: string }

  @IsOptional()
  @IsBoolean()
  status?: boolean
}

export class TraspasoConfiguracionDto {
  @IsString()
  tipo!: string //automatico, manual
}

export class InventarioTemporal {
  @IsNumber()
  cantidad!: number

  @ValidateNested()
  traspaso!: TraspasoDto

  @ValidateNested()
  producto!: ProductoDto

  @IsDateString()
  fecha!: Date
}
