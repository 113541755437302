import { IsBoolean, IsDate, IsDateString, IsNumber, IsOptional, IsString, Min, ValidateNested } from 'class-validator'

export class ClientePrecioEspecialProductoDto {
  @IsNumber()
  id!: number

  @IsOptional()
  @IsString()
  nombre!: string
}

export class ClienteBasico {
  @IsNumber()
  id!: number

  @IsOptional()
  @IsString()
  nombre!: string
}

export class ClientePrecioEspecialDto {
  @IsOptional()
  @IsNumber()
  id!: number

  @IsBoolean()
  porcentaje!: boolean

  @ValidateNested()
  cliente!: ClienteBasico

  @ValidateNested()
  producto!: ClientePrecioEspecialProductoDto

  @IsNumber()
  cantidadMinima!: number | null

  @IsDate()
  fechaExpiracion?: Date | null

  @IsOptional()
  @IsString()
  busqueda?: string

  @IsNumber()
  valor!: number
}

export class ClientePrecioEspecialCreateDto {
  @IsOptional()
  @IsNumber()
  id!: number

  @IsBoolean()
  porcentaje!: boolean

  @ValidateNested()
  cliente!: ClienteBasico

  @ValidateNested()
  producto!: ClientePrecioEspecialProductoDto

  @IsNumber()
  cantidadMinima!: number | null

  @IsOptional()
  @IsDateString()
  fechaExpiracion!: Date | string

  @IsNumber()
  @Min(0)
  valor!: number
}
