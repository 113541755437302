<form name="form" class="card rounded mt-4 p-4" #form="ngForm" autocomplete="off" (ngSubmit)="guardar()">
  <div class="grid grid-cols-12 gap-2">
    <div class="p-fluid col-span-full">
      <div class="p-field text-gris-obscuro">
        <label class="text-sm px-1">Alias del usuario</label>
        <input
          class="p-inputtext"
          type="text"
          #username="ngModel"
          (ngModelChange)="finalizado = false"
          [(ngModel)]="copia.username"
          required
          placeholder="Alias/Username"
          (change)="verificarAlias()"
          name="username"
        />
        <div *ngIf="aliasExiste">
          <span class="text-xs ml-1 text-red-800">Alias/Username ya registrado</span>
        </div>
        <div *ngIf="username.touched && username.errors">
          <span class="text-xs ml-1 text-red-800" *ngIf="username.errors.required">El alias es requerido</span>
        </div>
      </div>
    </div>
    <div class="p-fluid col-span-9">
      <div class="p-field text-gris-obscuro">
        <label class="text-sm px-1">Duración</label>
        <p-dropdown
          [options]="duracionesSesion"
          required
          (ngModelChange)="finalizado = false"
          [(ngModel)]="copia.duracion"
          optionLabel="descripcion"
          optionValue="id"
          class="p-dropdown flex w-full"
          placeholder="Duración"
          #duracion="ngModel"
          name="duracion"
        ></p-dropdown>
      </div>
    </div>
    <div class="p-fluid col-span-3 self-center text-center">
      <p-checkbox
        [(ngModel)]="copia.master"
        (ngModelChange)="finalizado = false"
        name="master"
        label="Master"
        [binary]="true"
      ></p-checkbox>
    </div>
  </div>
  <div *ngIf="form.dirty && !finalizado" class="mt-2">
    <button class="button" [ngClass]="{ 'p-disabled': form.invalid }" type="submit" [disabled]="form.invalid">
      Guardar
    </button>
  </div>
</form>
<p-toast position="bottom-right"></p-toast>
