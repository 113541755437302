import { IsOptional } from 'class-validator'
import { ProspectoCorreoDto, ProspectoTelefonoDto } from '@decet/crm-libreria-shared'

export class ListaProspectoDto {
  @IsOptional()
  id?: number

  @IsOptional()
  correos?: ProspectoCorreoDto[]

  @IsOptional()
  foto!: string

  nombre!: string

  @IsOptional()
  telefonos?: ProspectoTelefonoDto[]
}
