<div class="h-8 text-sm flex items-center p-4 hoverable" (click)="seleccion()">
  <button type="button">
    <i
      [ngClass]="{
        'fa fa-square text-gray-400': !data.seleccionado,
        'fas fa-check-square text-blue-500': data.seleccionado
      }"
    ></i>
  </button>
  <div class="flex-grow px-2">{{ data.descripcion }}</div>
</div>
