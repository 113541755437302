import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SesionService } from '@decet/sesion-browser'
import { IUsuarioAgregar, IUsuarios } from '../types'

@Injectable({
  providedIn: 'root',
})
export class AuthUsuariosUsuariosService {
  constructor(private http: HttpClient, private sesionService: SesionService) {}

  get(): Observable<IUsuarios> {
    return this.http.get<IUsuarios>(this.sesionService.url('usuarios'))
  }

  post(data: IUsuarioAgregar): Observable<IUsuarioAgregar> {
    return this.http.post<IUsuarioAgregar>(this.sesionService.url('usuarios'), data)
  }
}
