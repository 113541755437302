<div class="w-9/12 mx-auto">
  <div class="h-12 flex items-center">
    <h1 class="flex typo-headline self-center">Nuevo usuario</h1>
  </div>
  <form class="card p-4" name="form" #form="ngForm" autocomplete="off" (submit)="guardar()">
    <div class="grid grid-cols-12 gap-2">
      <div class="p-fluid col-span-12">
        <div class="p-field text-gris-obscuro">
          <label class="text-sm --text-clip">Correo</label>
          <input
            class="p-inputtext"
            #email="ngModel"
            [(ngModel)]="data.correo"
            [ngClass]="{ required: !data.correo }"
            [pattern]="patron"
            placeholder="Correo"
            required
            [minLength]="5"
            [maxLength]="30"
            (change)="verificarCorreo()"
            name="email"
            type="email"
          />
          <div *ngIf="email.touched">
            <ul class="at-control_messages" *ngIf="email.errors">
              <li *ngIf="email.errors.required" class="--text-clip" title="El correo es requerido">
                El correo es requerido
              </li>
              <li *ngIf="!email.errors.required && email.errors" class="--text-clip" title="El correo es requerido">
                El correo no tiene el formato correcto
              </li>
            </ul>
            <ul class="at-control_messages" *ngIf="correoExiste">
              <li>Correo ya registrado</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="p-fluid col-span-6">
        <div class="p-field text-gris-obscuro">
          <label class="text-sm --text-clip">username</label>
          <input
            class="p-inputtext"
            #aliasUser="ngModel"
            [(ngModel)]="data.username"
            [ngClass]="{ required: !data.username }"
            placeholder="Alias/Username"
            required
            (change)="verificarUsername()"
            name="username"
            type="text"
          />
          <div *ngIf="aliasUser.touched">
            <ul class="at-control_messages" *ngIf="aliasUser.errors">
              <li *ngIf="aliasUser.errors.required" class="--text-clip" title="El alias es requerido">
                El alias es requerido
              </li>
            </ul>
            <ul class="at-control_messages" *ngIf="verificaUser">
              <li>Nombre de usuario ya registrado</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="p-fluid col-span-6">
        <div class="p-field text-gris-obscuro">
          <label class="text-sm --text-clip">Sesión</label>
          <p-dropdown
            [ngClass]="{ required: !data.duracion }"
            name="duracion"
            #duracion="ngModel"
            required
            [options]="duracionesSesion"
            [(ngModel)]="data.duracion"
            optionLabel="descripcion"
            optionValue="id"
            placeholder="Duración"
          ></p-dropdown>
          <ul class="at-control_messages" *ngIf="duracion.errors && duracion.touched">
            <li *ngIf="duracion.errors.required" class="--text-clip" title="La duración es requerida">
              La duración es requerida
            </li>
          </ul>
        </div>
      </div>

      <div class="p-fluid col-span-12">
        <div class="p-field text-gris-obscuro">
          <p-checkbox [(ngModel)]="data.master" #master="ngModel" name="master" label="Master" [binary]="true">
          </p-checkbox>
        </div>
      </div>
      <div class="p-fluid col-span-6">
        <div class="p-field text-gris-obscuro">
          <label class="text-sm --text-clip">Nombre</label>
          <input
            class="p-inputtext"
            #name="ngModel"
            [(ngModel)]="data.nombre"
            [ngClass]="{ required: !data.nombre }"
            placeholder="Nombre"
            required
            name="nombre"
            type="text"
          />
          <ul class="at-control_messages" *ngIf="name.errors && name.touched">
            <li *ngIf="name.errors.required" class="--text-clip" title="El nombre es requerido">
              El nombre es requerido
            </li>
          </ul>
        </div>
      </div>

      <div class="p-fluid col-span-6">
        <div class="p-field text-gris-obscuro">
          <label class="text-sm --text-clip">Apellidos</label>
          <input
            class="p-inputtext"
            #apellidos="ngModel"
            [(ngModel)]="data.apellidos"
            [ngClass]="{ required: !data.apellidos }"
            placeholder="Apellidos"
            required
            name="apellidos"
            type="text"
          />
          <ul class="at-control_messages" *ngIf="apellidos.errors && apellidos.touched">
            <li *ngIf="apellidos.errors.required" class="--text-clip" title="Los apellidos son requeridos">
              Los apellidos son requeridos
            </li>
          </ul>
        </div>
      </div>

      <div class="p-fluid col-span-6">
        <div class="p-field text-gris-obscuro">
          <label class="text-sm --text-clip">Número celular</label>
          <input
            class="p-inputtext"
            #numeroCelular="ngModel"
            [(ngModel)]="data.numeroCelular"
            [ngClass]="{ required: !data.numeroCelular }"
            placeholder="Nº Celular"
            minlength="10"
            maxlength="10"
            required
            name="numeroCelular"
            pKeyFilter="pint"
            type="text"
          />
          <ul class="at-control_messages" *ngIf="numeroCelular.errors && numeroCelular.touched">
            <li *ngIf="numeroCelular.errors.required" class="--text-clip" title="El telefono es obligatorio">
              El telefono es obligatorio
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="pt-2">
      <button class="button" type="submit" [ngClass]="{ 'p-disabled': form.invalid }" [disabled]="form.invalid">
        Continuar
      </button>

      <button class="button button-secondary" style="margin-left: 5px" [routerLink]="['../']" type="reset">
        Cancelar
      </button>
    </div>
  </form>
</div>
<auth-usuarios-usuarios-agregar-confirmar *ngIf="confirmarGuardar" [data]="data" (cerrar)="cerrarModal($event)">
</auth-usuarios-usuarios-agregar-confirmar>
