import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IAgrupacionesUsuarioPermisos, IUsuarioPermisos } from '../../../../../../types'

@Injectable({
  providedIn: 'root',
})
export class AuthUsuariosUsuarioEmpresaPermisosService {
  constructor(@Inject(HttpClient) private http: HttpClient) {}

  get(usuarioId: number, empresaId: number): Observable<IUsuarioPermisos> {
    return this.http.get<IUsuarioPermisos>(`/api/auth/usuarios/usuario/${usuarioId}/empresa/${empresaId}/permisos`)
  }

  put(data: IAgrupacionesUsuarioPermisos, usuarioId: number, empresaId: number): Observable<IUsuarioPermisos> {
    return this.http.put<IUsuarioPermisos>(
      `/api/auth/usuarios/usuario/${usuarioId}/empresa/${empresaId}/permisos`,
      data,
    )
  }
}
