import { ErrorHandler, Injectable, Type } from '@angular/core'
import { plainToClass } from 'class-transformer'
import { validateSync } from 'class-validator'
import { getValue } from '@decet/core/shared/common'
import { APP_ENVIRONMENT } from '../constants/app-environment'

const CLASS_TO_PLAIN_OPTIONS = {
  enableImplicitConversion: true,
}

const VALIDATOR_OPTIONS = {
  forbidUnknownValues: true,
  skipMissingProperties: false,
  whitelist: true,
}

export function Environment(key = '') {
  return function <T extends Type<object>>(constructor: T): any {
    @Injectable({
      providedIn: 'root',
      useFactory: (environment: object, errorHandler: ErrorHandler) => {
        const plain = getValue(environment, key) ?? {}
        const instance = plainToClass(constructor, plain, CLASS_TO_PLAIN_OPTIONS)
        const errors = validateSync(instance, VALIDATOR_OPTIONS)

        if (errors.length) {
          const error = new Error(errors.toString())
          errorHandler.handleError(error)
        }

        return instance
      },
      deps: [APP_ENVIRONMENT, ErrorHandler],
    })
    class Configuration extends constructor {}

    return Configuration
  }
}
