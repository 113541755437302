import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IModulos } from '../../../types'

@Injectable({
  providedIn: 'root',
})
export class ModulosService {
  data = [
    {
      Id: 1,
      aplicacionId: 1,
      aplicacion: 'importacion xml',
      modulo: 'solicitar descargas',
    },
  ]

  constructor(@Inject(HttpClient) private http: HttpClient) {}

  get(data: any): Observable<IModulos> {
    return new Observable<IModulos>((subscrip) => {
      subscrip.next(this.data)
    })
  }
}
