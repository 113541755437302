import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { ErrorHandler, Injectable } from '@angular/core'
import { Observable, tap } from 'rxjs'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private errorHandler: ErrorHandler) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        error: (response: HttpErrorResponse) => this.errorHandler.handleError(response.error),
      }),
    )
  }
}
