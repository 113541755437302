import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IAuthUsuariosUsuarioConfiguracionRestablecerResponse } from '../../../types'

@Injectable({
  providedIn: 'root',
})
export class UsuariosUsuarioConfiguracionRestablcerService {
  constructor(@Inject(HttpClient) private http: HttpClient) {}

  post(correo: string, usuarioId: number): Observable<IAuthUsuariosUsuarioConfiguracionRestablecerResponse> {
    return this.http.post<any>(`/api/auth/usuarios/usuario/${usuarioId}/restablecer`, { correo })
  }
}
