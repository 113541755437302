import { IsBoolean, IsNumber, IsOptional, IsString } from 'class-validator'

export class GruposDto {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsString()
  descripcion!: string

  @IsString()
  codigo!: string

  img?: any

  @IsOptional()
  @IsBoolean()
  ecommerce?: boolean
}
