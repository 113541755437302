import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { MessageService } from 'primeng/api'
import { VerificarSesionAliasService } from '../../../apis/usuario/alias.service'
import { UsuariosUsuarioSesionService } from '../../../apis/usuario/sesion.service'
import { IAuthUsuariosUsuarioSesion } from '../../../types'

const duraciones = [
  { id: 15, descripcion: '15 minutos' },
  { id: 30, descripcion: '30 minutos' },
  { id: 45, descripcion: '45 minutos' },
  { id: 60, descripcion: '1 hora' },
  { id: 120, descripcion: '2 horas' },
  { id: 180, descripcion: '3 horas' },
  { id: 240, descripcion: '4 horas' },
  { id: 720, descripcion: '8 horas' },
]

@Component({
  selector: 'auth-usuarios-usuario-sesion',
  templateUrl: './sesion.component.html',
  styleUrls: ['./sesion.component.scss'],
})
export class AuthUsuariosUsuarioSesionComponent implements OnInit {
  @Input() dataSesion: IAuthUsuariosUsuarioSesion
  copia: IAuthUsuariosUsuarioSesion
  duracionesSesion = duraciones
  error = ''
  editando = false
  finalizado = true
  usuarioId: number
  aliasExiste = false

  constructor(
    private usuariosUsuarioSesionService: UsuariosUsuarioSesionService,
    private aliasService: VerificarSesionAliasService,
    private routeActived: ActivatedRoute,
    private messageService: MessageService,
  ) {
    this.usuarioId = this.routeActived.snapshot.params.usuarioId
  }

  ngOnInit(): void {
    this.copia = Object.assign(this.dataSesion)
  }

  guardar(): void {
    console.log(this.aliasExiste, 'existe')
    console.log(this.editando, 'editando')
    if (this.aliasExiste || this.editando) {
      return
    }

    this.error = ''
    this.editando = true

    this.usuariosUsuarioSesionService
      .put(this.copia, this.usuarioId)
      .toPromise()
      .then(() => {
        this.finalizado = true
        this.messageService.add({
          severity: 'success',
          life: 1400,
          closable: false,
          summary: 'Usuario',
          detail: 'Se actualizó la sesión del usuario',
        })
        this.editando = false
        this.dataSesion = this.copia
      })
      .catch(({ error }) => {
        this.error = error.error || error
      })
  }

  verificarAlias(): void {
    this.aliasService
      .get(this.dataSesion.username, this.usuarioId)
      .toPromise()
      .then((data) => (this.aliasExiste = data))
  }
}
