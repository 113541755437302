import { NgZone } from '@angular/core'
import { assertIsFunction } from '@decet/core/shared/common'

export function OutsideZone(): PropertyDecorator {
  return function (_target: Object, propertyKey: string | symbol, descriptor: PropertyDescriptor = {}) {
    const { set, value } = descriptor

    if (value) {
      assertIsFunction(value, new Error(`Property ${String(propertyKey)} must be a method.`))
      descriptor.value = outsideZone(value)
    } else if (set) {
      descriptor.set = outsideZone(set)
    }

    return descriptor
  }
}

function outsideZone(method: (...args: any[]) => any) {
  return function (this: object, ...args: any[]) {
    assertsHasNgZone(this)

    return this.ngZone.runOutsideAngular(() => method.call(this, ...args))
  }
}

function assertsHasNgZone(object: any): asserts object is { ngZone: NgZone } {
  if (!('ngZone' in object)) {
    throw new Error(`Class with 'OutsideZone' decorator should have 'ngZone' class property with 'NgZone' class.`)
  }
}
