import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IUsuarioEstatus } from '../../types'

@Injectable({
  providedIn: 'root',
})
export class UsuariosUsuarioEstatusService {
  constructor(@Inject(HttpClient) private http: HttpClient) {}

  put(estatus: boolean, usuarioId: number): Observable<IUsuarioEstatus> {
    return this.http.put<IUsuarioEstatus>(`/api/auth/usuarios/usuario/${usuarioId}/estatus`, { estatus })
  }
}
