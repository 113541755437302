export const TIPOCREDITO = [
  { codigo: 'dias-credito', descripcion: 'Días crédito' },
  { codigo: 'periodicidad', descripcion: 'Periodicidad' },
]

export const PERIODICIDAD = [
  { codigo: 'mensual', descripcion: 'Mensual' },
  { codigo: 'quincenal', descripcion: 'Quincenal' },
  { codigo: 'semanal', descripcion: 'Semanal' },
]

export const DIASSEMANA = [
  { index: 0, codigo: 'lunes', descripcion: 'Lunes' },
  { index: 1, codigo: 'martes', descripcion: 'Martes' },
  { index: 2, codigo: 'miercoles', descripcion: 'Miercoles' },
  { index: 3, codigo: 'jueves', descripcion: 'Jueves' },
  { index: 4, codigo: 'viernes', descripcion: 'Viernes' },
  { index: 5, codigo: 'sabado', descripcion: 'Sábado' },
  { index: 6, codigo: 'domingo', descripcion: 'Domingo' },
]
