import { IsNumber } from 'class-validator'

export class CashRegisterLiquidacionDetalleItems {
  @IsNumber()
  numero!: number

  @IsNumber()
  total!: number
}

export class CashRegisterCorteLiquidacionDetalle {
  retiros!: CashRegisterLiquidacionDetalleItems
  facturas!: CashRegisterLiquidacionDetalleItems
  creditos!: CashRegisterLiquidacionDetalleItems
  fondo!: CashRegisterLiquidacionDetalleItems
}
