import { IsNumber, IsOptional } from 'class-validator'

export class GestionInventarioDto {
  @IsOptional()
  @IsNumber()
  id!: any

  @IsNumber()
  sucursalId!: number

  @IsNumber()
  inventarioId!: number

  @IsNumber()
  cantidad!: number

  @IsNumber()
  motivoId!: number
}

export class GestionInventarioEnvioDto {
  @IsOptional()
  @IsNumber()
  id!: any

  @IsNumber()
  inventarioId!: number

  @IsNumber()
  cantidad!: number

  @IsNumber()
  motivoId!: number
}
