import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthUsuariosUsuarioInvitacionComponent } from './components/usuario/invitacion/invitacion/invitacion.component'
import { AuthUsuariosUsuarioComponent } from './components/usuario/usuario.component'
import { AuthUsuariosUsuariosAgregarComponent } from './components/usuarios/agregar/agregar.component'
import { AuthUsuariosUsuariosComponent } from './components/usuarios/usuario.component'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'usuarios',
    pathMatch: 'full',
  },
  {
    path: 'usuarios',
    component: AuthUsuariosUsuariosComponent,
  },
  {
    path: 'usuarios/agregar',
    component: AuthUsuariosUsuariosAgregarComponent,
  },
  {
    path: 'usuario/:usuarioId',
    component: AuthUsuariosUsuarioComponent,
  },
  {
    path: 'usuario/:usuarioId/invitacion',
    component: AuthUsuariosUsuarioInvitacionComponent,
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AutUsuariosRoutingModule {}
