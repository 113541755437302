import { Inject, Injectable } from '@angular/core'
import { timer, fromEvent, concat, Observable, merge } from 'rxjs'
import { mapTo, switchMap, share, startWith } from 'rxjs/operators'
import { IDLE_DETECTOR_CONFIG } from '../constants/idle-detector-config'
import { IdleDetectorConfig } from '../interfaces/idle-detector-config'

@Injectable({ providedIn: 'root' })
export class IdleDetectorService {
  private resetEvents$ = merge(...createResetEvents(this.config.resetEvents)).pipe(share())

  constructor(@Inject(IDLE_DETECTOR_CONFIG) private config: IdleDetectorConfig) {}

  start<S extends string, I extends string>(start: S, intervals: Record<I, number>): Observable<{ status: S | I }> {
    const intervals_: Record<S & I, number> = { [start]: 0, ...intervals }
    const timers$ = concat(...createEvents(intervals_))

    return this.resetEvents$.pipe(
      startWith(null),
      switchMap(() => timers$),
    )
  }
}

function createEvents<T extends string>(intervals: Record<T, number>): Observable<{ status: T }>[] {
  const entries = Object.entries<number>(intervals).sort(([, a], [, b]) => a - b) as [T, number][]

  return entries.map(([status, delay]) => timer(delay).pipe(mapTo({ status })))
}

function createResetEvents(events: string[]): Observable<null>[] {
  return events.map((eventName) => fromEvent(window, eventName, { capture: true }, () => null))
}
