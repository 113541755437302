import { IsBoolean } from 'class-validator'

export class PosProductoConfiguracionDto {
  @IsBoolean()
  codigoRequerido!: boolean

  @IsBoolean()
  codigoBarras!: boolean

  @IsBoolean()
  codigoSAT!: boolean

  @IsBoolean()
  departamento!: boolean

  @IsBoolean()
  familia!: boolean

  @IsBoolean()
  grupo!: boolean
}
