import { Type } from 'class-transformer'
import { IsOptional, IsString, Length, ValidateNested } from 'class-validator'
import { ContactoDto } from '@decet/crm-libreria-shared'
import { CreditConfiguracionDto } from './credito.dto'

export class ProveedorDto {
  @IsOptional()
  id?: any

  @IsString()
  razonSocial!: string

  @IsString()
  nombre!: string

  @IsString()
  @Length(12, 13)
  rfc!: string

  @ValidateNested()
  @Type(() => CreditConfiguracionDto)
  credito!: CreditConfiguracionDto | null

  @ValidateNested({ each: true })
  @Type(() => ContactoDto)
  contactos!: ContactoDto[]
}
