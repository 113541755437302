import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IAuthUsuariosUsuarioSesion } from '../../types'

@Injectable({
  providedIn: 'root',
})
export class UsuariosUsuarioSesionService {
  constructor(@Inject(HttpClient) private http: HttpClient) {}

  put(data: IAuthUsuariosUsuarioSesion, usuarioId: number): Observable<IAuthUsuariosUsuarioSesion> {
    return this.http.put<IAuthUsuariosUsuarioSesion>(`/api/auth/usuarios/usuario/${usuarioId}/sesion`, data)
  }
}
