import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AuthUsuariosUsuariosService } from '../../../../apis/usuarios.service'
import { IUsuarioAgregar } from '../../../../types'

@Component({
  selector: 'auth-usuarios-usuarios-agregar-confirmar',
  templateUrl: './confirmar.component.html',
  styleUrls: ['./confirmar.component.scss'],
})
export class AuthUsuariosUsuariosAgregarConfirmarComponent implements OnInit {
  displayModal: boolean
  procesando: boolean
  error: any
  exito: boolean
  @Input() data: IUsuarioAgregar
  @Output() cerrar: EventEmitter<IUsuarioAgregar | null> = new EventEmitter()

  constructor(private usuariosService: AuthUsuariosUsuariosService) {
    this.displayModal = true
    this.procesando = false
    this.error = null
    this.exito = false
  }

  ngOnInit() {
    console.log('confirmar')
  }

  finalizar(data: IUsuarioAgregar | null = null): void {
    this.displayModal = false
    this.cerrar.emit(data)
  }

  guardar(): void {
    if (this.procesando) {
      return
    }

    this.procesando = true

    this.usuariosService
      .post(this.data)
      .toPromise()
      .then((data) => {
        this.exito = true
        this.data = data
        this.procesando = false
        this.procesando = false
      })
      .catch((error) => {
        this.procesando = false
        this.error = error.error.error
        this.procesando = false
      })
  }
}
