import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class UnidadNegocioService {
  constructor(private http: HttpClient) {}

  get(unidadNegocioId: number): Observable<any> {
    return this.http.get(`/api/unidad-negocio/${unidadNegocioId}`)
  }
}
