export function scaleBetween(target: [min: number, max: number], origin: [min: number, max: number], value: number) {
  return ((target[1] - target[0]) * (value - origin[0])) / (origin[1] - origin[0]) + target[0]
}

export function round(value: number, precision = 0): number {
  if (!Number.isInteger(precision) || precision < 0) throw new Error('Round precision must be a positive integer.')

  const { mant, exp } = numberToFloat(value)
  const newValue = numberToFloat(Math.round(Number(mant + 'e' + (exp + precision))))
  return Number(newValue.mant + 'e' + (newValue.exp - precision))
}

function numberToFloat(val: number) {
  const [mant, exp] = Number(val)
    .toExponential()
    .split('e')
    .map((str) => Number(str))

  return { mant, exp }
}

export function clamp(min: number, value: number, max: number): number {
  return Math.min(Math.max(min, value), max)
}
