import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SesionService } from '../../../../../sesion/browser/src/lib/services/sesion.service'
import { SeccionInterface } from '../../../../types'

@Injectable({
  providedIn: 'root',
})
export class AuthSesionModuloService {
  constructor(@Inject(HttpClient) private http: HttpClient, private authService: SesionService) {}

  get(seccion: string): Observable<SeccionInterface> {
    return this.http.get<SeccionInterface>(this.authService.url(`/auth/sesion/modulo/${seccion}`))
  }
}
