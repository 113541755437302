import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { MessageService } from 'primeng/api'
import { map } from 'rxjs/operators'
import { IAgrupacionesUsuarioPermisos, IUsuarioPermisos } from '../../../../../../../types'
import { AuthUsuariosUsuarioCuentaPermisosService } from '../../../../apis/usuario/cuenta/permisos.service'

@Component({
  selector: 'auth-usuarios-usuario-permisos',
  templateUrl: './permisos.component.html',
})
export class AuthUsuariosUsuarioPermisosComponent implements OnInit {
  @Input() cambio: string
  switch: boolean
  filtro = ''
  menu: string
  permisos: IUsuarioPermisos = []
  resultados: IAgrupacionesUsuarioPermisos = []
  usuarioId!: number
  modificado = false

  constructor(
    private usuarioCuentaPermisosService: AuthUsuariosUsuarioCuentaPermisosService,
    private activateRoute: ActivatedRoute,
    private messageService: MessageService,
  ) {
    this.usuarioId = activateRoute.snapshot.params.usuarioId
  }

  ngOnInit(): void {
    this.usuarioCuentaPermisosService.get(this.usuarioId).subscribe((data) => {
      this.permisos = data
      this.filtrar()
    })
  }

  filtrar(): void {
    const filtro = this.filtro
    const resultados = this.permisos.filter((item) => item.descripcion.includes(filtro))
    this.resultados = resultados.reduce((r, { agrupacion }) => {
      if (!r.some((o) => o.agrupacion.id == agrupacion.id)) {
        r.push({ agrupacion, items: resultados.filter((v) => v.agrupacion.id === agrupacion.id) })
      }
      return r
    }, [])
  }

  guardar(): void {
    this.usuarioCuentaPermisosService
      .put(this.resultados, this.usuarioId)
      .pipe(
        map((response) => {
          this.modificado = false
          this.messageService.add({
            severity: 'success',
            life: 1400,
            closable: false,
            summary: 'Permisos de cuenta',
            detail: 'Se actualizo correctamente la información',
          })
          this.resultados = [].concat(this.resultados)
          return response
        }),
      )
      .subscribe()
  }

  seleccionar(): void {
    this.modificado = true
  }
}
