import { Component, Input, OnInit } from '@angular/core'
import { IUsuarios } from '../../../types'

const diacriticosRegex = /[,.\u0300-\u036f]/g
const diacriticosEspacios = /\s+\s/g
const normalizar = (valor: string) =>
  valor.normalize('NFD').replace(diacriticosRegex, '').replace(diacriticosEspacios, ' ').toUpperCase().trim()

@Component({
  selector: 'auth-usuarios-usuarios-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss'],
})
export class AuthUsuariosUsuariosListaComponent implements OnInit {
  @Input() data: IUsuarios
  filtro = ''
  resultados: IUsuarios = []

  constructor() {}

  ngOnInit(): void {
    console.log(this.data, 'data de')
    this.resultados = this.data = this.data.map((i) => {
      if (!Array.isArray(i.nombre)) {
        return {
          id: i.id,
          nombre: i.nombre,
          apellidos: i.nombre,
          correo: i.correo,
          activo: i.activo,
          inicializado: i.inicializado,
          usuarioId: i.usuarioId,
          busqueda: normalizar(`${i.nombre}-${i.correo}`),
        }
      }
    })
  }

  filtrar(): void {
    const filtro = normalizar(this.filtro)
    this.resultados = this.data.filter((item) => item.busqueda.includes(filtro))
  }

  usuario(item): void {
    console.log(item)
  }
}
