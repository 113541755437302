import { IsNumber, IsString, IsOptional } from 'class-validator'

export class CatalogoCiudadDto {
  @IsNumber()
  id?: number

  @IsString()
  descripcion!: string

  @IsOptional()
  @IsNumber()
  estadoId?: number

  identificacion?: string
  paisId?: number
  municipioId?: number
}
