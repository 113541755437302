import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthSesionAutorizacionComponent } from './components/autorizacion/autorizacion.component'
import { AuthSesionCurrentComponent } from './components/current/current.component'
import { AuthSesionLoginComponent } from './components/login/login.component'
import { AuthSesionFinalizarComponent } from './components/finalizar/finalizar.component'
import { AuthSesionReestablecerComponent } from './components/reestablecer/reestablecer.component'
import { AuthSesionRegistrarComponent } from './components/registrar/registrar.component'

const routes: Routes = [
  {
    path: 'login',
    component: AuthSesionLoginComponent,
  },
  {
    path: 'current',
    component: AuthSesionCurrentComponent,
  },
  {
    path: 'authorization',
    component: AuthSesionAutorizacionComponent,
  },
  {
    path: 'finalizar',
    component: AuthSesionFinalizarComponent,
  },
  {
    path: 'sesion/finalizar',
    component: AuthSesionFinalizarComponent,
  },
  {
    path: 'sesion/reestablecer',
    component: AuthSesionReestablecerComponent,
  },
  { path: 'usuarios/verificar/:token', component: AuthSesionRegistrarComponent },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AutSesionRoutingModule {}
