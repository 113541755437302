<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '70vw', maxWidth: '450px' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [showHeader]="false"
  [resizable]="false"
  *ngIf="!procesando && !exito"
>
  <div class="at-item at-item--large">
    <h1 class="typo-title flex">Nuevo usuario</h1>
  </div>
  <p>Está a punto de guardar un nuevo usuario<br />¿Desea continuar?</p>
  <div *ngIf="error">
    <p class="p-m-0 --text-error">
      {{ error }}
    </p>
  </div>

  <div class="flex gap-2 flex-row-reverse">
    <button class="button" (click)="guardar()">Continuar</button>
    <button class="button button-outline" (click)="finalizar()">Cancelar</button>
  </div>
</p-dialog>
<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '70vw', maxWidth: '450px' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [showHeader]="false"
  [resizable]="false"
  *ngIf="procesando && !exito"
>
  <div class="at-item at-item--large">
    <h1 class="typo-title flex">Nuevo usuario</h1>
  </div>
  <p>Guardando usuario...</p>
</p-dialog>

<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '70vw', maxWidth: '450px' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [showHeader]="false"
  [resizable]="false"
  *ngIf="!procesando && exito"
>
  <div class="at-item at-item--large">
    <h1 class="typo-title flex">Nuevo usuario</h1>
  </div>
  <p>
    <i class="fa fa-check-circle --text-success" style="color: green"></i>
    Se guardo el nuevo usuario
  </p>
  <div class="at-item at-item--reverse">
    <button class="p-button" style="margin-left: 5px" [routerLink]="['../../usuario', data.id]">Configuración</button>
    <button class="p-button p-button-text" (click)="finalizar(data)">Nuevo</button>
  </div>
</p-dialog>
