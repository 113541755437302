import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AuthSesionLoginService } from '../../../../sesion/apis/login.service'
import { VerificarCorreoService } from '../../../apis/usuario/correo.service'
import { VerificarUsernameService } from '../../../apis/usuario/username.service'
import { IUsuarioAgregar } from '../../../types'

const duraciones = [
  { id: 15, descripcion: '15 minutos' },
  { id: 30, descripcion: '30 minutos' },
  { id: 45, descripcion: '45 minutos' },
  { id: 60, descripcion: '60 minutos' },
  { id: 120, descripcion: '120 minutos' },
  { id: 180, descripcion: '180 minutos' },
  { id: 240, descripcion: '240 minutos' },
]

@Component({
  selector: 'auth-usuarios-usuarios-agregar',
  templateUrl: './agregar.component.html',
  styleUrls: ['./agregar.component.scss'],
})
export class AuthUsuariosUsuariosAgregarComponent implements OnInit {
  confirmarGuardar = false
  duracionesSesion = duraciones
  error = ''
  warn = ''
  data: IUsuarioAgregar = this.limpiarData()
  patron = `^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$`
  correoExiste = false
  verificaUser = false
  promise: Promise<void | boolean> = null

  constructor(
    private correoService: VerificarCorreoService,
    private usernameService: VerificarUsernameService,
    private authSesion: AuthSesionLoginService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (!this.authSesion.isAuth()) {
      this.router.navigateByUrl('/sesion/login').then((r) => r)
    }
  }

  cerrarModal(data): void {
    this.confirmarGuardar = false
    if (data) {
      this.data = this.limpiarData()
    }
  }

  guardar(): void {
    if (!this.correoExiste && !this.verificaUser) {
      this.confirmarGuardar = true
    }
  }

  limpiarData(): IUsuarioAgregar {
    return {
      master: false,
      id: null,
      duracion: null,
      correo: null,
      apellidos: '',
      nombre: '',
      username: '',
      numeroCelular: '',
    }
  }

  verificarCorreo(): void {
    console.log('se està validando correo')
    this.correoService
      .get(this.data.correo)
      .toPromise()
      .then((data) => {
        this.correoExiste = data
      })
  }

  verificarUsername(): void {
    this.usernameService
      .get(this.data.username)
      .toPromise()
      .then((data) => (this.verificaUser = data))
  }
}
