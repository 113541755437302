import { Component, Input, Output, EventEmitter } from '@angular/core'
import { AuthUsuariosUsuarioInvitacionService } from '../../../../apis/usuario/invitacion.service'
import { IAuthUsuariosUsuarioInformacion } from '../../../../types'

@Component({
  selector: 'auth-usuarios-usuario-invitacion-confirmar',
  templateUrl: './confirmar.component.html',
})
export class AuthUsuariosUsuarioInvitacionConfirmarComponent {
  displayModal: boolean
  @Input() data!: IAuthUsuariosUsuarioInformacion
  exito: boolean
  procesando: boolean
  error: any
  display: boolean
  mensaje: string
  @Output() cerrar: EventEmitter<boolean> = new EventEmitter()

  constructor(private invitacionService: AuthUsuariosUsuarioInvitacionService) {
    this.displayModal = true
    this.procesando = false
    this.exito = false
    this.error = ''
    this.display = true
    this.mensaje = ''
  }

  async guardar(): Promise<void> {
    if (this.procesando) {
      return
    }

    this.procesando = true
    this.error = null
    await this.invitacionService
      .post(this.data)
      .toPromise()
      .then((response: any) => {
        this.mensaje = ''
        if (response && response.mensaje) {
          this.mensaje = response.mensaje
        }
        this.exito = true
        this.procesando = false
      })
      .catch((error) => {
        this.error = error
        if (error.error && error.error.error) {
          this.error = error.error.error
        }
        this.procesando = false
      })
  }

  salir(): void {
    this.cerrar.emit()
  }
}
