import { Injectable, OnDestroy } from '@angular/core'
import { filter, map, Observable, Subject } from 'rxjs'

interface BroadcastMessage<T = any> {
  type: string
  payload: T
}

@Injectable({ providedIn: 'root' })
export class BroadcastService implements OnDestroy {
  private name = 'default'

  private channel: BroadcastChannel | null = null

  private isClosed = false

  private messages = new Subject<BroadcastMessage>()

  public messages$ = this.messages.asObservable()

  private onMessage = ({ data }: MessageEvent) => this.messages.next(data)

  public get closed(): boolean {
    return this.isClosed
  }

  constructor() {
    this.start()
  }

  public post(type: string, payload: any): void {
    if (!this.channel) {
      throw new DOMException(`Failed to post message on channel '${this.name}': Channel is closed`)
    }

    this.channel.postMessage({ type, payload })
  }

  public listen<T>(type: string): Observable<T> {
    return this.messages.pipe(
      filter((m): m is BroadcastMessage<T> => m.type === type),
      map(({ payload }) => payload),
    )
  }

  public start(): void {
    if (this.closed) {
      throw new DOMException(`Failed to start channel '${this.name}': Channel is closed`)
    }

    if (this.channel) return

    this.channel = new BroadcastChannel(this.name)
    this.channel.addEventListener('message', this.onMessage)
  }

  public stop() {
    if (this.closed) {
      throw new DOMException(`Failed to stop channel '${this.name}': Channel is closed`)
    }

    if (!this.channel) return

    this.channel.removeEventListener('message', this.onMessage)
    this.channel.close()
    this.channel = null
  }

  public close() {
    if (this.closed) return

    this.stop()
    this.messages.complete()
    this.isClosed = true
  }

  ngOnDestroy(): void {
    this.close()
  }
}
