import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav'
import { Router } from '@angular/router'

@Component({
  selector: 'decet-crm',
  templateUrl: './crm.component.html',
  styleUrls: ['./crm.component.scss'],
})
export class DecetCRMComponent implements OnInit, OnDestroy {
  mode = 'side'

  @ViewChild(MatSidenavContainer, { static: true })
  container: MatSidenavContainer

  @ViewChild(MatSidenav, { static: true, read: ElementRef })
  content: ElementRef<HTMLElement>

  private _collapsed: boolean = localStorage.getItem('menuOculto') === '1'

  public get collapsed() {
    return this._collapsed
  }

  public set collapsed(collapsed: boolean) {
    localStorage.setItem('menuOculto', collapsed ? '1' : '0')
    this._collapsed = collapsed
    this.updateLayout()
  }

  opened = false

  private resizeObserver = new ResizeObserver(() => {
    if (this.collapsed) return
    this.updateLayout()
  })

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.resizeObserver.observe(this.content.nativeElement)
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect()
  }

  private updateLayout() {
    requestAnimationFrame(() => this.container.updateContentMargins())
  }
}
