import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { MessageService } from 'primeng/api'
import { map } from 'rxjs/operators'
import { IAgrupacionesUsuarioPermisos, IUsuarioPermisos } from '../../../../../../../types'
import { AuthUsuariosUsuarioEmpresaPermisosService } from '../../../../apis/usuario/empresa/permisos.service'
import { AuthUsuariosUsuarioEmpresaService } from '../../../../apis/usuario/empresas.service'

@Component({
  selector: 'auth-usuarios-usuario-empresa-permisos',
  templateUrl: './permisos.component.html',
  styleUrls: ['./permisos.component.scss'],
})
export class AuthUsuariosUsuarioEmpresaPermisosComponent implements OnInit {
  filtro = ''
  empresas = []
  //TODO: obtener el empresaId del combo
  empresaId: number | null = 1
  permisos: IUsuarioPermisos = []
  resultados: IAgrupacionesUsuarioPermisos = []
  usuarioId!: number
  modificado = false

  constructor(
    private usuarioPermisoEmpresaService: AuthUsuariosUsuarioEmpresaPermisosService,
    private routeActived: ActivatedRoute,
    private usuariosUsuarioEmpresaService: AuthUsuariosUsuarioEmpresaService,
    private messageService: MessageService,
  ) {
    this.usuarioId = routeActived.snapshot.params.usuarioId
  }

  ngOnInit(): void {
    this.usuariosUsuarioEmpresaService.get().subscribe((empresas) => {
      this.empresas = empresas
      if (this.empresas.length) {
        this.empresaId = this.empresas[0].id
        this.buscarPermisos()
      }
    })
  }

  buscarPermisos(): void {
    this.modificado = false
    this.usuarioPermisoEmpresaService.get(this.usuarioId, this.empresaId).subscribe((data) => {
      this.permisos = data
      this.filtrar()
    })
  }

  guardar(): void {
    this.usuarioPermisoEmpresaService
      .put(this.resultados, this.usuarioId, this.empresaId)
      .pipe(
        map((response) => {
          this.modificado = false
          this.messageService.add({
            severity: 'success',
            life: 1400,
            closable: false,
            summary: 'Permisos de empresa',
            detail: 'Se modificaron correctamente',
          })
          this.resultados = [].concat(this.resultados)
          return response
        }),
      )
      .subscribe()
  }

  seleccionar() {
    this.modificado = true
  }

  filtrar(): void {
    const filtro = this.filtro
    const resultados = this.permisos.filter((item) => item.descripcion.includes(filtro))
    this.resultados = resultados.reduce((r, { agrupacion }) => {
      if (!r.some((o) => o.agrupacion.id == agrupacion.id)) {
        r.push({ agrupacion, items: resultados.filter((v) => v.agrupacion.id === agrupacion.id) })
      }
      return r
    }, [])
  }
}
