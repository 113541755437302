<ng-container *ngIf="workspace$ | async as workspace">
  <button
    class="flex items-center gap-2 px-3 py-2 w-full hover:bg-slate-200"
    type="button"
    matRipple
    *ngIf="!collapsed; else workspaceAvatar"
  >
    <div class="flex-1 truncate">
      <div class="truncate text-left">{{ workspace.nombre }}</div>
      <div class="truncate text-left text-sm opacity-60">{{ workspace.correo }}</div>
    </div>
    <!-- <div class="w-6 h-6">
      <fa-icon [icon]="icons.expand" size="sm"></fa-icon>
    </div> -->
  </button>
  <ng-template #workspaceAvatar>
    <div class="flex flex-col items-center my-2 only-collapsed">
      <div class="avatar h-7 w-7 px-2">{{ workspace.nombre.slice(0, 2) | uppercase }}</div>
    </div>
  </ng-template>
</ng-container>
