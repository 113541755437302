<p-dialog
  [(visible)]="displayModal && !procesando && !exito"
  [modal]="true"
  [style]="{ width: '50vw', minWidth: '350px', maxWidth: '450px' }"
  [baseZIndex]="10000"
  [draggable]="false"
  header="Enviar invitación"
  [resizable]="false"
>
  <!--  <div class="flex">-->
  <!--    <h1 class="typo-title flex">Enviar invitación</h1>-->
  <!--  </div>-->
  <p class="pb-4">¿Seguro que desea enviar invitación?</p>
  <div class="flex flex-row-reverse mt2 gap-2">
    <button class="button" (click)="guardar()">Continuar</button>
    <button class="button button-secondary" (click)="salir()">Cancelar</button>
  </div>
</p-dialog>
<p-dialog
  [(visible)]="displayModal && procesando && !exito"
  [modal]="true"
  [style]="{ width: '50vw', minWidth: '350px', maxWidth: '450px' }"
  [baseZIndex]="10000"
  [draggable]="false"
  header="Enviar invitación"
  [resizable]="false"
>
  <!--  <div class="flex">-->
  <!--    <h1 class="typo-title flex-grow">Enviar invitación</h1>-->
  <!--  </div>-->
  <p class="pb-4">Enviando invitación...</p>
</p-dialog>

<p-dialog
  [(visible)]="displayModal && !procesando && exito"
  [modal]="true"
  [style]="{ width: '50vw', minWidth: '350px', maxWidth: '450px' }"
  [baseZIndex]="10000"
  [draggable]="false"
  header="Enviar invitación"
  [resizable]="false"
>
  <!--  <div class="flex">-->
  <!--    <h1 class="typo-title flex-grow"></h1>-->
  <!--  </div>-->

  <p class="pb-4">Se ha enviado la invitacion.</p>
  <div class="flex flex-row-reverse mt2">
    <button class="button" [routerLink]="['../']">Salir</button>
  </div>
</p-dialog>
