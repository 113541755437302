import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IUsuarioCambiar } from '../../../../../types'

@Injectable({
  providedIn: 'root',
})
export class AuthSesionReestablecerCambiarService {
  constructor(private $http: HttpClient) {}

  get(token: string): Observable<object> {
    return this.$http.get(`/api/importar-xml/sesion/reestablecer/${token}`)
  }

  put(token: string, data: IUsuarioCambiar): Observable<object> {
    return this.$http.put(`/api/importar-xml/sesion/reestablecer/${token}`, data)
  }
}
