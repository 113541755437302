import { Component, EventEmitter, Input, Output } from '@angular/core'
import { IAgrupacionUsuarioPermisos } from '../../../../../../../../../types'

@Component({
  selector: 'auth-usuarios-usuario-empresa-permisos-lista-agrupacion',
  templateUrl: './agrupacion.component.html',
  styleUrls: ['./agrupacion.component.scss'],
})
export class AuthUsuariosUsuarioEmpresaPermisoListaAgrupacionComponent {
  todosSeleccionados = true
  @Output() selected = new EventEmitter<void>()
  @Input() item!: IAgrupacionUsuarioPermisos

  seleccionar() {
    this.selected.emit()
  }
}
