import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { NETWORK_CACHE } from '../constants/network-cache'
import { NetworkCache } from '../interfaces/network-cache'

@Injectable({ providedIn: 'root' })
export class CacheInterceptor implements HttpInterceptor {
  constructor(@Inject(NETWORK_CACHE) private cache: NetworkCache) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isCacheable(req)) return next.handle(req)

    const cachedResponse = this.cache.get(req.url)
    return cachedResponse ? cachedResponse : this.sendRequest(req, next)
  }

  private isCacheable(req: HttpRequest<unknown>) {
    return req.method.toUpperCase() === 'GET'
  }

  private sendRequest(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const response = next.handle(req)

    this.cache.set(req.url, response)

    return response
  }
}
