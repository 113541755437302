import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { UsuariosUsuarioConfiguracionEliminarService } from '../../../../apis/usuario/configuracion/eliminar.service'
import { IAuthConfiguracion } from '../../../../types'
//import { UsuarioService } from '../../../../apis/usuario/usuario.service';

@Component({
  selector: 'auth-usuarios-usuario-configuracion-confirmar-eliminar',
  templateUrl: './eliminar.component.html',
})
export class AuthUsuariosUsuarioConfiguracionConfirmarEliminarComponent {
  displayModal: boolean
  procesando: boolean
  error: any
  exito: boolean
  usuarioId: number
  //@Input() empresaId: number;
  @Input() data: IAuthConfiguracion
  @Output() cerrar: EventEmitter<boolean> = new EventEmitter()

  constructor(
    //private usuarioService: UsuarioService,
    private usuariosUsuarioConfiguracionEliminarService: UsuariosUsuarioConfiguracionEliminarService,
    private routeActived: ActivatedRoute,
  ) {
    this.usuarioId = this.routeActived.snapshot.params.usuarioId
    this.displayModal = true
    this.procesando = false
    this.error = null
    this.exito = false
  }

  finalizar(): void {
    this.displayModal = false
    //this.procesando = false;
    this.cerrar.emit()
  }

  eliminar(): void {
    this.error = ''
    if (this.procesando) {
      return
    }

    this.usuariosUsuarioConfiguracionEliminarService
      .delete(this.usuarioId)
      .toPromise()
      .then(() => {
        this.exito = true
        this.procesando = false
      })
      .catch((error) => {
        this.error = error.data || error
        this.procesando = false
      })
  }
}
