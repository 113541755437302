import { HttpClient } from '@angular/common/http'
import { Injectable, Inject } from '@angular/core'
import { Observable } from 'rxjs'
import { IAuthConfiguracion } from '../../../types'

@Injectable({
  providedIn: 'root',
})
export class UsuariosUsuarioConfiguracionEliminarService {
  constructor(@Inject(HttpClient) private http: HttpClient) {}

  delete(usuarioId: number): Observable<IAuthConfiguracion> {
    return this.http.delete<any>(`/api/auth/usuarios/usuario/${usuarioId}/eliminar`)
  }
}
