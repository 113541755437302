import { IsDate, IsNumber, IsOptional, IsString } from 'class-validator'

export class DocumentosDto {
  @IsOptional()
  @IsNumber()
  id!: number

  @IsString()
  uuid!: string

  @IsString()
  nombre!: string

  @IsString()
  size!: string

  @IsDate()
  capturaFecha!: Date

  @IsString()
  extension!: string
}
