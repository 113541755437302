import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IEmpresaPermisos } from '../../types'

@Injectable({
  providedIn: 'root',
})
export class UsuarioPermisosService {
  constructor(@Inject(HttpClient) private $http: HttpClient) {}

  get(usuarioId: number): Observable<IEmpresaPermisos> {
    return this.$http.get<IEmpresaPermisos>(`/api/usuario/${usuarioId}/permisos`)
  }

  post(usuarioId: number, data: IEmpresaPermisos): Observable<IEmpresaPermisos> {
    return this.$http.post<IEmpresaPermisos>(`/api/usuario/${usuarioId}/permisos`, data)
  }
}
