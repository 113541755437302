<section *ngIf="data" class="w-11/12 mx-auto">
  <div class="h-12 flex items-center">
    <h1 class="flex typo-headline self-center">Información personal</h1>
  </div>
  <div class="card rounded p-4">
    <div class="flex h-16">
      <div class="flex-grow flex-col">
        <div class="text-sm typo-subhead">{{ data.nombre + ' ' + data.apellidos | titlecase }}</div>
        <div>{{ data.correo }}</div>
      </div>
      <button
        class="button"
        type="button"
        *ngIf="data.inicializado"
        [textContent]="data.activo ? 'Inhabilitar' : 'Habilitar'"
        [ngClass]="{ 'button-error': data.activo, '': !data.activo }"
        (click)="status()"
      ></button>
      <button class="button" [routerLink]="['invitacion']" type="button" *ngIf="!data.inicializado">INVITAR</button>
    </div>
    <div class="flex">
      <span
        class="step"
        pTooltip="Información del usuario"
        tooltipPosition="top"
        [ngClass]="{ selected: informacion }"
        (click)="seleccionar('informacion')"
      >
        <i class="pi pi-info mr-1"></i> {{ 'información' | titlecase }}
      </span>
      <span
        class="step"
        pTooltip="Sesión de usuario"
        tooltipPosition="top"
        [ngClass]="{ selected: sesion }"
        (click)="seleccionar('sesion')"
      >
        <i class="pi pi-user mr-1"></i> {{ 'Sesión' | titlecase }}
      </span>
      <span
        class="step"
        pTooltip="Configuración de usuario"
        tooltipPosition="top"
        [ngClass]="{ selected: configuracion }"
        (click)="seleccionar('configuracion')"
      >
        <i class="pi pi-cog mr-1"></i> {{ 'Configuración' | titlecase }}
      </span>
      <span
        class="step"
        pTooltip="Permisos de cuenta"
        tooltipPosition="top"
        [ngClass]="{ selected: permisos }"
        (click)="seleccionar('permisos')"
      >
        <i class="pi pi-key mr-1"></i> {{ 'Cuenta' | titlecase }}
      </span>
      <span
        class="step"
        pTooltip="Permisos para empresas"
        tooltipPosition="top"
        [ngClass]="{ selected: permisosEmpresa }"
        (click)="seleccionar('permisosEmpresa')"
      >
        <i class="pi pi-key mr-1"></i> {{ 'Empresas' | titlecase }}
      </span>
    </div>
  </div>
  <auth-usuarios-usuario-informacion
    *ngIf="informacion"
    [activo]="data.activo"
    [data]="dataInformacion"
  ></auth-usuarios-usuario-informacion>

  <auth-usuarios-usuario-sesion *ngIf="sesion" [dataSesion]="dataSesion"></auth-usuarios-usuario-sesion>

  <auth-usuarios-usuario-configuracion *ngIf="configuracion" [data]="data"></auth-usuarios-usuario-configuracion>

  <auth-usuarios-usuario-permisos *ngIf="permisos"></auth-usuarios-usuario-permisos>

  <auth-usuarios-usuario-empresa-permisos *ngIf="permisosEmpresa"></auth-usuarios-usuario-empresa-permisos>

  <!--*ngIf="modulos['usuarios.informacion']"TODO: descomentar esta validaciòn-->

  <!--*ngIf="modulos['usuarios.sesion']"TODO: descomentar esta validaciòn-->

  <!--*ngIf="modulos['usuarios.configuracion']"TODO: descomentar esta validaciòn-->

  <!--*ngIf="modulos['usuarios.permisos']"TODO: descomentar esta validaciòn-->
</section>
<auth-usuarios-usuario-estatus
  *ngIf="cambiandoEstatus"
  [data]="dataEstatus"
  (cerrar)="finalizarEstatus($event)"
></auth-usuarios-usuario-estatus>
