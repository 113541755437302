import { CrmCuentaConfiguracionDto } from '@decet/crm-libreria-shared'

export class CrmEmailPostDto {
  id!: any
  plataformaId!: number
  privada!: boolean
  identificador!: string
  agentes: any | { id: number }[]
  correo!: string
  configuracion!: CrmCuentaConfiguracionDto
  configuracionEmail!: crmEmailConfiguracionDto
}

export class crmEmailConfiguracionDto {
  email!: string
  cuentaId!: number
  descripcion!: string
  activo?: boolean
}

export class crmEmailTemplateDto {
  id!: number
  descripcion!: string
}
