import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AuthSesionLoginService } from '../../apis/login.service'

@Component({
  selector: 'auth-sesion-autorizacion',
  templateUrl: './autorizacion.component.html',
  styleUrls: ['./autorizacion.component.scss'],
})
export class AuthSesionAutorizacionComponent implements OnInit {
  constructor(private router: Router, private sesion: AuthSesionLoginService) {}

  ngOnInit(): void {
    if (!this.sesion.isAuth()) {
      this.router.navigateByUrl('/sesion/login').then()
    }
  }
}
