import { IsBoolean, IsDate, IsNumber, IsNumberString, IsOptional, IsString, ValidateNested } from 'class-validator'
import { CatalogoSucursalDto } from '../compras/compras.dto'
import { AlmacenDto, Usuarios } from './traspasos.dto'

export class SucursalesEmitDto {
  @IsNumber()
  almacenId!: number

  @IsNumber()
  sucursalId!: number
}
export class SolicitudProductoDto {
  @IsNumber()
  id!: number

  @IsString()
  nombre!: string

  @IsNumberString()
  codigo!: string
}

export class SolicitudDto {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsOptional()
  @IsString()
  notas?: string

  @IsDate()
  fecha!: Date

  @ValidateNested()
  sucursal!: CatalogoSucursalDto

  @ValidateNested()
  almacen!: AlmacenDto

  @IsOptional()
  @ValidateNested()
  usuario?: Usuarios

  @IsBoolean()
  status!: boolean

  @IsOptional()
  @ValidateNested()
  detalle?: SolicitudDetalleDto[]
}

export class SolicitudCreateDto {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsString()
  fecha!: string | Date

  @ValidateNested()
  sucursal!: CatalogoSucursalDto

  @ValidateNested()
  almacen!: AlmacenDto

  @IsOptional()
  @IsString()
  notas?: string

  @IsOptional()
  @ValidateNested()
  usuario?: Usuarios

  @IsOptional()
  @ValidateNested()
  detalle?: SolicitudDetalleDto[]

  @IsOptional()
  @IsBoolean()
  status?: boolean
}

export class SolicitudDetalleDto {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsString()
  codigo!: string

  @IsOptional()
  @IsNumber()
  cantidad?: number

  @IsOptional()
  @IsNumber()
  cantidadSolicitada?: number

  @IsOptional()
  @IsString()
  notas?: string

  @IsNumber()
  existencia!: number

  @ValidateNested()
  producto!: SolicitudProductoDto

  @IsOptional()
  @IsBoolean()
  status?: boolean
}
