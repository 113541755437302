import { throttle, ThrottleSettings } from 'lodash'

export function Throttle(delay: number, options?: ThrottleSettings) {
  return function (_target: Object, _propertyKey: string | symbol, descriptor: PropertyDescriptor) {
    if (descriptor.set) {
      descriptor.set = throttle(descriptor.set, delay, options)
    } else if (descriptor.get) {
      descriptor.get = throttle(descriptor.get, delay, options)
    } else if (descriptor.value) {
      descriptor.value = throttle(descriptor.value, delay, options)
    }
  }
}
