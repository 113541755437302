<p-dialog
  header="Eliminar usuario"
  [closable]="false"
  *ngIf="displayModal && !procesando && !exito"
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '50vw', minWidth: '350px', maxWidth: '450px' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <p class="text-sm">
    Se eliminará para siempre el usuario y ya no podrás recuperarlo <br />
    ¿Desea continuar?
  </p>
  <div *ngIf="error">
    <p class="text-red-600">
      {{ error }}
    </p>
  </div>
  <ng-template pTemplate="footer">
    <button class="button button-secondary" (click)="finalizar()">Cancelar</button>
    <button type="button" class="button" (click)="eliminar()">Continuar</button>
  </ng-template>
</p-dialog>
<p-dialog
  header="Eliminar usuario"
  [closable]="false"
  *ngIf="displayModal && procesando"
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '50vw', minWidth: '350px', maxWidth: '450px' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <p class="text-sm">
    <p-progressSpinner
      [style]="{ width: '50px', height: '25px' }"
      styleClass="custom-spinner"
      strokeWidth="8"
      animationDuration=".5s"
    ></p-progressSpinner>
    Eliminando usuario...
  </p>
</p-dialog>
<p-dialog
  header="Eliminar usuario"
  [closable]="false"
  *ngIf="displayModal && exito"
  [(visible)]="displayModal && exito"
  [modal]="true"
  [style]="{ width: '50vw', minWidth: '350px', maxWidth: '450px' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <p class="text-sm"><i class="fa fa-circle-check text-green"></i> Se elimino el usuario seleccionado</p>
  <ng-template pTemplate="footer">
    <button type="button" [routerLink]="['../']" class="button">Finalizar</button>
  </ng-template>
</p-dialog>
