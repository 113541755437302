import { Component, Input } from '@angular/core'
import { IUsuarioCompleto } from '../../../types'

@Component({
  selector: 'auth-usuarios-usuario-configuracion',
  templateUrl: './configuracion.component.html',
})
export class AuthUsuariosUsuarioConfiguracionComponent {
  respuesta = false
  bandera = false
  @Input() data!: IUsuarioCompleto

  eliminar(): void {
    this.respuesta = true
  }

  restablecer(): void {
    this.bandera = true
  }

  eliminarResponse(): void {
    this.respuesta = false
  }

  restablecerResponse(): void {
    this.bandera = false
  }
}
