import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { UsuariosUsuarioConfiguracionRestablcerService } from '../../../../apis/usuario/configuracion/restablecer.service'

@Component({
  selector: 'auth-usuarios-usuario-configuracion-reestablecer-confirmar',
  templateUrl: './reestablecer.component.html',
})
export class AuthUsuariosUsuarioConfiguracionReestablecerConfirmarComponent {
  displayModal: boolean
  procesando: boolean
  error: any
  exito: boolean
  usuarioId: number
  @Input() correo: string
  @Output() cerrar: EventEmitter<void> = new EventEmitter()

  constructor(
    private usuariosUsuarioConfiguracionRestablcerService: UsuariosUsuarioConfiguracionRestablcerService,
    private routeActived: ActivatedRoute,
  ) {
    this.displayModal = true
    this.usuarioId = this.routeActived.snapshot.params.usuarioId
    this.procesando = false
    this.error = null
    this.exito = false
  }

  finalizar(): void {
    this.displayModal = false
    this.cerrar.emit()
  }

  guardar(): void {
    this.error = ''
    if (this.procesando) {
      return
    }

    this.procesando = true

    this.usuariosUsuarioConfiguracionRestablcerService
      .post(this.correo, this.usuarioId)
      .toPromise()
      .then(() => {
        this.exito = true
        this.procesando = false
      })
      .catch((error) => {
        this.error = error.data || error
        this.procesando = false
      })
  }
}
