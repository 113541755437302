import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { MessageService } from 'primeng/api'
import { ButtonModule } from 'primeng/button'

//Primeng
import { CardModule } from 'primeng/card'
import { CheckboxModule } from 'primeng/checkbox'
import { DialogModule } from 'primeng/dialog'
import { DropdownModule } from 'primeng/dropdown'
import { InputSwitchModule } from 'primeng/inputswitch'
import { InputTextModule } from 'primeng/inputtext'
import { KeyFilterModule } from 'primeng/keyfilter'
import { PanelModule } from 'primeng/panel'
import { ProgressSpinnerModule } from 'primeng/progressspinner'
import { TabViewModule } from 'primeng/tabview'
import { ToastModule } from 'primeng/toast'
import { TooltipModule } from 'primeng/tooltip'
import { AuthSesionModule } from '../sesion/sesion.module'
import { AuthUsuariosUsuarioConfiguracionComponent } from './components/usuario/configuracion/configuracion.component'
import { AuthUsuariosUsuarioConfiguracionConfirmarEliminarComponent } from './components/usuario/configuracion/eliminar/eliminar.component'
import { AuthUsuariosUsuarioConfiguracionReestablecerConfirmarComponent } from './components/usuario/configuracion/reestablecer/reestablecer.component'
import { AgrupacionComponent } from './components/usuario/dominio/permisos/lista/agrupacion/agrupacion.component'
import { AuthUsuariosUsuarioDominioPermisosListaItemComponent } from './components/usuario/dominio/permisos/lista/item/item.component'
import { AuthUsuariosUsuarioDominioPermisosListaComponent } from './components/usuario/dominio/permisos/lista/lista.component'
import { AuthUsuariosUsuarioPermisosComponent } from './components/usuario/dominio/permisos/permisos.component'
import { AuthUsuariosUsuarioEmpresaPermisoListaAgrupacionComponent } from './components/usuario/empresa/permisos/lista/agrupacion/agrupacion.component'
import { AuthUsuariosUsuarioEmpresaPermisosListaItemComponent } from './components/usuario/empresa/permisos/lista/item/item.component'
import { AuthUsuariosUsuarioEmpresaListaComponent } from './components/usuario/empresa/permisos/lista/lista.component'
import { AuthUsuariosUsuarioEmpresaPermisosComponent } from './components/usuario/empresa/permisos/permisos.component'
import { AuthUsuariosUsuarioEstatusComponent } from './components/usuario/estatus/estatus.component'
import { AuthUsuariosUsuarioInformacionComponent } from './components/usuario/informacion/informacion.component'
import { AuthUsuariosUsuarioInvitacionConfirmarComponent } from './components/usuario/invitacion/confirmar/confirmar.component'
import { AuthUsuariosUsuarioInvitacionComponent } from './components/usuario/invitacion/invitacion/invitacion.component'
import { AuthUsuariosUsuarioSesionComponent } from './components/usuario/sesion/sesion.component'
import { AuthUsuariosUsuarioComponent } from './components/usuario/usuario.component'
import { AuthUsuariosUsuariosAgregarComponent } from './components/usuarios/agregar/agregar.component'
import { AuthUsuariosUsuariosAgregarConfirmarComponent } from './components/usuarios/agregar/confirmar/confirmar.component'
import { AuthUsuariosUsuariosListaItemComponent } from './components/usuarios/lista/item/item.component'
import { AuthUsuariosUsuariosListaComponent } from './components/usuarios/lista/lista.component'
import { AuthUsuariosUsuariosComponent } from './components/usuarios/usuario.component'
import { AutUsuariosRoutingModule } from './usuarios-routing.module'

@NgModule({
  declarations: [
    AuthUsuariosUsuariosComponent,
    AuthUsuariosUsuariosListaComponent,
    AuthUsuariosUsuariosListaItemComponent,
    AuthUsuariosUsuariosAgregarComponent,
    AuthUsuariosUsuariosAgregarConfirmarComponent,
    AuthUsuariosUsuarioComponent,
    AuthUsuariosUsuarioEstatusComponent,
    AuthUsuariosUsuarioInformacionComponent,
    AuthUsuariosUsuarioInvitacionComponent,
    AuthUsuariosUsuarioInvitacionConfirmarComponent,
    AuthUsuariosUsuarioSesionComponent,
    AuthUsuariosUsuarioConfiguracionComponent,
    AuthUsuariosUsuarioConfiguracionConfirmarEliminarComponent,
    AuthUsuariosUsuarioConfiguracionReestablecerConfirmarComponent,
    AuthUsuariosUsuarioPermisosComponent,
    AuthUsuariosUsuarioEmpresaPermisosComponent,
    AuthUsuariosUsuarioEmpresaListaComponent,
    AuthUsuariosUsuarioEmpresaPermisoListaAgrupacionComponent,
    AuthUsuariosUsuarioEmpresaPermisosListaItemComponent,
    AgrupacionComponent,
    AuthUsuariosUsuarioDominioPermisosListaComponent,
    AuthUsuariosUsuarioDominioPermisosListaItemComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    RouterModule,
    AutUsuariosRoutingModule,
    AuthSesionModule,
    CheckboxModule,
    DialogModule,
    ButtonModule,
    CardModule,
    TabViewModule,
    ProgressSpinnerModule,
    PanelModule,
    InputSwitchModule,
    ToastModule,
    InputTextModule,
    TooltipModule,
    KeyFilterModule,
  ],
  providers: [MessageService],
})
export class AuthUsuariosModule {}
