export class ActividadEvents {
  nombre: string
  correo: string
  telefono: string
  actividad: string
  etiquetas: any
  contactoId: number | undefined
  dominioId = 1
  empresaId = 2

  public static Name = () => {
    return 'actividades.agregar'
  }

  constructor(nombre: string, correo: string, telefono: string, actividad: string, contactoId?: number) {
    console.log('constructor de la actividad')
    this.correo = correo
    console.log(this.correo, 'correo')
    this.nombre = nombre
    console.log(this.nombre, 'nombre')
    this.telefono = telefono
    console.log(this.telefono, 'telefono')
    this.actividad = actividad
    console.log(this.actividad, 'actividad')
    this.contactoId = contactoId
    console.log(this.contactoId, 'contactoId')
  }
}
