import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IAuthUsuariosUsuarioInformacion } from '../../types'

@Injectable({
  providedIn: 'root',
})
export class UsuariosUsuarioInformacionService {
  constructor(@Inject(HttpClient) private http: HttpClient) {}

  put(data: IAuthUsuariosUsuarioInformacion, usuarioId: number): Observable<IAuthUsuariosUsuarioInformacion> {
    return this.http.put<IAuthUsuariosUsuarioInformacion>(`/api/auth/usuarios/usuario/${usuarioId}/informacion`, data)
  }
}
