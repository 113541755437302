import { IsNumber, IsNumberString, IsOptional } from 'class-validator'

export class ProspectosDocumentosDto {
  @IsOptional()
  @IsNumber()
  id!: number

  @IsNumberString()
  contactoId!: number

  @IsNumberString()
  catalogoId!: number

  @IsOptional()
  nombre!: string

  documento!: any
}
