import { Type } from 'class-transformer'
import { IsBoolean, IsNumber, IsOptional, IsString } from 'class-validator'

export class CreditConfiguracionDto {
  @IsNumber()
  @Type(() => Number)
  plazos!: number | null

  @IsOptional()
  @IsString()
  tipo!: string

  @IsOptional()
  @IsString()
  periodicidad?: string | null

  @IsOptional()
  @IsString()
  diaCorte?: string

  @IsOptional()
  @IsNumber()
  @Type(() => Number)
  diasCredito?: number | null

  @IsNumber()
  @Type(() => Number)
  limite!: number | null

  @IsBoolean()
  @Type(() => Boolean)
  excederLimite!: boolean
}
