import { ProspectoCorreoDto } from '../../prospectos/correo.dto'

export class InfobipEmailMasivoPost {
  alias!: string
  subject!: string
  sender!: string
  mensaje!: string
  correos!: ProspectoCorreoDto[]
}
export class InfobipEmailPost {
  alias!: string
  subject!: string
  sender!: string
  mensaje!: string
  correo!: string
}
