import { IsNumber, IsString } from 'class-validator'

export class ProspectoCuentaPlaformaDto {
  @IsNumber()
  cuenta!: any
  plataforma!: ProspectoCuentaPlataformaDto
}
export class ProspectoCuentaPlataformaDto {
  @IsNumber()
  id!: number
  @IsString()
  descripcion!: string
}
