import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule, Route } from '@angular/router'

export const sesionBrowserRoutes: Route[] = []

@NgModule({
  imports: [CommonModule, RouterModule],
})
export class SesionBrowserModule {}
