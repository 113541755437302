<section style="width: 500px; margin: 0 auto">
  <div class="flex items-center h-10">
    <h1 class="typo-title text-white" style="opacity: 1">Registrar usuario</h1>
  </div>
  <form
    class="bg-white card p-4 rounded flex flex-col gap-2"
    #form="ngForm"
    *ngIf="!completado"
    autocomplete="off"
    (submit)="verificar()"
  >
    <p>Hola, se le vinculó una cuenta a su correo electrónico, siga los pasos para inciar sesión</p>
    <p class="mt-4">Para continuar es necesario asignar contraseña y aceptar los terminos y serivicios.</p>
    <div class="flex flex-col">
      <label>Contraseña</label>
      <input
        class="p-inputtext"
        autofocus
        type="password"
        required
        placeholder="Contraseña"
        pattern="(?=.*\d)(?=.*[!@#$%^&*.,])(?=.*[a-z])(?=.*[A-Z]).{8,}"
        name="password"
        #password="ngModel"
        [(ngModel)]="data.password"
      />
      <ul class="at-control_messages" *ngIf="password.errors">
        <li *ngIf="password.errors.required && password.touched">Contraseña requerida</li>
        <li *ngIf="password.errors.pattern && password.touched">Contraseña en formato incorrecto</li>
      </ul>
    </div>
    <div class="flex flex-col">
      <label>Repita su contraseña</label>
      <input
        class="p-inputtext"
        type="password"
        required
        placeholder="Repite Contraseña"
        pattern="(?=.*\d)(?=.*[!@#$%^&*.,])(?=.*[a-z])(?=.*[A-Z]).{8,}"
        name="passwordRepeat"
        #passwordRepeat="ngModel"
        [(ngModel)]="data.passwordRepeat"
      />
      <ul class="at-control_messages" *ngIf="passwordRepeat.errors">
        <li *ngIf="passwordRepeat.errors.required && passwordRepeat.touched">Contraseña requerida</li>
        <li *ngIf="passwordRepeat.errors.pattern && passwordRepeat.touched">Contraseña en formato incorrecto</li>
      </ul>
    </div>
    <div class="">
      <p-checkbox
        [(ngModel)]="data.aceptoTerminosServicio"
        #aceptoTerminosServicio="ngModel"
        name="aceptoTerminosServicio"
        label="Acepto los términos y condiciones"
        [binary]="true"
      ></p-checkbox>
    </div>
    <div class="text-red-500" *ngIf="error">
      <div *ngIf="error">
        {{ error.message }}
      </div>
    </div>
    <div class="text-align-right margin-md">
      <button class="button" type="submit" aria-disabled="true" [disabled]="form.invalid || procesando">
        Finalizar
      </button>
    </div>
  </form>
  <!--p *ngIf="!valido">
    No se encontro al usuario
  </p-->
  <section class="card p-4 rounded flex flex-col gap-2" *ngIf="completado">
    <p class="mt-4">
      <i class="fa fa-check-circle text-green-700"></i>
      Su usuario ha registrado correctamente, por favor inicie sesión
    </p>
    <div class="text-align-right mt-4">
      <button class="button" [routerLink]="['/sesion/login']" [queryParams]="{ redirect: '' }">Iniciar sesión</button>
    </div>
  </section>
</section>
