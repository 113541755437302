import { Component, Input } from '@angular/core'
import { IUsuario } from '../../../../types'

@Component({
  selector: 'auth-usuarios-usuarios-lista-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class AuthUsuariosUsuariosListaItemComponent {
  @Input() data: IUsuario

  constructor() {}
}
