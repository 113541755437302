import { IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'

export class CatalogoDocumentosDto {
  @IsOptional()
  @IsNumber()
  id!: number

  @IsString()
  descripcion!: string
}

export class bulkCatalogoDocumentosDto {
  @ValidateNested({ each: true })
  bulk!: CatalogoDocumentosDto[]
}
