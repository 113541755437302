import { Type } from 'class-transformer'
import { IsBoolean, IsOptional, IsString } from 'class-validator'
import { interaccionesDto } from './interaccion.dto'

export class listaChatDto {
  prospecto!: any
  capturaFecha!: Date
  interaccion?: any

  @IsOptional()
  @IsBoolean()
  destacado?: boolean

  @IsOptional()
  @IsString()
  estatus?: string

  @IsOptional()
  @Type(() => interaccionesDto)
  //TODO:ESTO
  interacciones?: any[]
}
