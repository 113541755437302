import { fromEvent, Observable, Subject } from 'rxjs'
import { filter, map, mergeWith, shareReplay, startWith, tap } from 'rxjs/operators'
import { ReactiveStorageEvent } from './reactive-storage-event'
import { ReactiveStorageState } from './reactive-storage-state'

const SEPARATOR = '.'

const filterPrefixedKeys = (prefix: string, key: string | null): boolean => {
  return key === null || key.startsWith(prefix + SEPARATOR)
}

export abstract class ReactiveStorage<T extends ReactiveStorageState> {
  protected abstract prefix: string

  private storage: Storage = window.localStorage

  private cache: Partial<T> = {}

  private storageEvents$ = fromEvent<StorageEvent>(window, 'storage').pipe(
    filter(({ storageArea, key }: StorageEvent): boolean => {
      return storageArea === this.storage && filterPrefixedKeys(this.prefix, key)
    }),
    map((event: StorageEvent): ReactiveStorageEvent<T> => {
      const key = event.key === null ? null : event.key.slice((this.prefix + SEPARATOR).length)
      const value = event.key === null ? null : this.get(event.key as any)

      return new ReactiveStorageEvent(key, value)
    }),
  )

  private changes = new Subject<ReactiveStorageEvent<T>>()

  public changes$ = this.storageEvents$.pipe(
    mergeWith(this.changes),
    filter(({ key, value }: ReactiveStorageEvent<T>): boolean => {
      return key === null || (this.cache[key] ?? null) !== value
    }),
    tap(({ key, value }: ReactiveStorageEvent<T>) => {
      if (key === null) {
        this.cache = {}
      } else if (value === null) {
        delete this.cache[key]
      } else {
        key
        this.cache[key] = value
      }
    }),
    shareReplay({ bufferSize: 1, refCount: false }),
  )

  public get size(): number {
    return Object.keys(this.storage).filter((key) => filterPrefixedKeys(this.prefix, key)).length
  }

  observe<K extends keyof T & string>(key: K): Observable<T[K]> {
    return this.changes$.pipe(
      startWith({ key, value: this.get(key as any) }),
      filter((event) => event.key === null || event.key === key),
      map(({ value }) => value as T[K]),
    )
  }

  get<K extends keyof T & string>(key: K): T[K] {
    const value = this.storage.getItem(this.prefix + SEPARATOR + key)

    return value === null ? null : JSON.parse(value)
  }

  set<K extends keyof T & string>(key: K, value: T[K]): void {
    this.storage.setItem(this.prefix + SEPARATOR + key, JSON.stringify(value))
    this.changes.next(new ReactiveStorageEvent(key, value))
  }

  delete(key: string): void {
    this.storage.removeItem(this.prefix + SEPARATOR + key)
    this.changes.next(new ReactiveStorageEvent(key, null))
  }

  clear(): void {
    this.storage.clear()
    this.changes.next(new ReactiveStorageEvent(null, null))
  }
}
