import { Type } from 'class-transformer'
import { IsDate, IsNumber, IsOptional, IsString } from 'class-validator'
import 'reflect-metadata'

export class TareaDto {
  @IsNumber()
  id?: number

  @IsString()
  descripcion!: string

  @IsOptional()
  @IsDate()
  @Type(() => Date)
  capturaFecha?: Date

  @IsDate()
  @Type(() => Date)
  fecha!: Date

  @IsDate()
  @Type(() => Date)
  fechaRecordatorio!: Date

  @IsNumber()
  status!: number

  @IsOptional()
  @IsNumber()
  usuarioId?: number
}

export class TareaRecordatorioDto {
  @IsString()
  descripcion!: string

  @IsNumber()
  milisegundos!: number
}
