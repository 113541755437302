import { Location } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute, Router } from '@angular/router'
import { finalize, switchMap, take } from 'rxjs/operators'
import { AuthSesionLoginService } from '../../apis/login.service'

@Component({
  selector: 'auth-sesion-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class AuthSesionLoginComponent implements OnInit {
  @Input() redirect = ''
  procesando = false
  credencialForm = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required]),
  })

  constructor(
    private sesion: AuthSesionLoginService,
    private location: Location,
    private snackBar: MatSnackBar,
    private router: Router,
    private routedActive: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.redirect = this.routedActive.snapshot.queryParams.redirect
    console.log(this.redirect, 'redirect login')
  }

  public aceptar(): Promise<void> {
    if (!this.credencialForm.valid) return
    if (this.procesando) return

    this.procesando = true

    this.sesion
      .auth(this.credencialForm.value)
      .pipe(
        take(1),
        switchMap((response) => {
          this.sesion.setSession(response)
          return this.sesion.current()
        }),
        finalize(() => (this.procesando = false)),
      )
      .subscribe({
        next: () => {
          if (this.redirect !== undefined) {
            this.router.navigate([this.redirect])
          } else {
            this.location.back()
          }
        },
        error: ({ error }) => this.logError(error.message ?? error),
      })
  }

  private logError(error: string) {
    this.snackBar.open(error, '', { panelClass: 'bg-red-500', duration: 3000 })
  }
}
