import { IsNumber, IsOptional, IsString } from 'class-validator'

export class KardexDto {
  @IsNumber() id!: number

  @IsString() uid!: string

  @IsOptional() @IsString() descripcion?: string
}

export class KardexCreateDto {
  @IsOptional() @IsString() descripcion!: string
}
