import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { UsuarioService } from '../../apis/usuario/usuario.service'
import {
  IUsuarioCompleto,
  IUsuarioEstatus,
  IAuthUsuariosUsuarioInformacion,
  IAuthUsuariosUsuarioSesion,
} from '../../types'

@Component({
  selector: 'auth-usuarios-usuario-id',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss'],
})
export class AuthUsuariosUsuarioComponent implements OnInit {
  @Input() usuarioId: number
  resetear = 0
  @Input() data: IUsuarioCompleto
  dataInformacion: IAuthUsuariosUsuarioInformacion
  dataEstatus: IUsuarioEstatus
  dataSesion: IAuthUsuariosUsuarioSesion
  informacion = false
  sesion = false
  configuracion = false
  permisos = false
  permisosEmpresa = false
  cambiandoEstatus = false
  modulos: any[]

  constructor(private actRoute: ActivatedRoute, private usuarioService: UsuarioService, private router: Router) {
    this.usuarioId = this.actRoute.snapshot.params.usuarioId
    this.modulos = []
  }

  ngOnInit(): void {
    const step = this.actRoute.snapshot.queryParams.seccion
    this.procesarSeccion(step)

    if (!this.sesion && !this.configuracion && !this.permisos && !this.permisosEmpresa) {
      this.informacion = true
    }

    this.usuarioService
      .get(this.usuarioId)
      .toPromise()
      .then((data) => {
        this.data = data
        this.dataInformacion = {
          id: this.data.id,
          correo: this.data.correo,
          nombre: this.data.nombre,
          apellidos: this.data.apellidos,
          numeroCelular: this.data.numeroCelular,
        }
        this.dataEstatus = {
          id: this.data.id,
          activo: this.data.activo,
        }
        this.dataSesion = {
          id: this.data.id,
          username: this.data.username,
          duracion: this.data.duracion,
          master: this.data.master,
        }
      })
  }

  finalizarEstatus(event): void {
    this.cambiandoEstatus = false
    this.resetear = 0
    if (event) {
      this.data = Object.assign(this.data, event)
    }
  }

  procesarSeccion(step: string): void {
    this.informacion = step === 'informacion'
    this.sesion = step === 'sesion'
    this.configuracion = step === 'configuracion'
    this.permisos = step === 'permisos'
    this.permisosEmpresa = step === 'permisosEmpresa'
  }

  seleccionar(step): void {
    this.procesarSeccion(step)
    this.router.navigate([], {
      queryParams: {
        seccion: step,
      },
    })
  }

  status(): void {
    this.cambiandoEstatus = true
  }
}
