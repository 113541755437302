import { IsNumber, IsString } from 'class-validator'

export class VincularAgentePutDto {
  @IsNumber()
  contactoId!: number

  @IsNumber()
  agenteIdNuevo!: number

  @IsString()
  agenteNombre!: string
  //TODO:INTERFAZ DE CUENTA
  cuentas!: any[]
}
export class VincularAgenteParamsDto {
  contactoId!: number
}
export class AgentesCuentasParamsDto {
  agenteId!: number
}
