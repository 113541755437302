import { IsOptional, IsString } from 'class-validator'

export class nose {
  hola!: string
}

export class FiltroCuentasDto {
  id!: number | null
  plataformaId!: number | null
  descripcion!: string
  alias?: string
}

export type PlataformasInterface = 'whatsapp' | 'email' | 'sms' | 'facebook' | 'Instagram' | 'Página web'

export class FiltroPlataformasDto {
  id!: number
  descripcion!: PlataformasInterface
  prospectos!: number[]
  cuenta!: FiltroCuentasDto
}

export class FiltroCuentasResponseDto {
  cuenta!: FiltroCuentasDto[]
  current!: any
}

export class FiltroActividadCumpleanioDto {
  @IsOptional()
  @IsString()
  fechaInicial!: string

  @IsOptional()
  @IsString()
  fechaFinal!: string
}

export class FiltroActividadDto {
  @IsString()
  descripcion!: string

  @IsOptional()
  actividades!: any

  @IsOptional()
  cobranzas!: any

  @IsOptional()
  cumple!: any

  @IsOptional()
  etiquetas!: any

  @IsOptional()
  clasificacion!: any

  @IsOptional()
  opcional!: boolean
}

export class FiltroActividadesDto {
  actividad!: ActividadFiltroDto
  fechaInicial!: Date
  fechaFinal!: Date
}

export class ActividadFiltroDto {
  id!: any
  tipo!: string
  descripcion!: string
}

export class FiltroProspectoPlataformaDto {
  contactoId!: number
  plataformaId!: number[]
}
export class FiltroSegmentoDto {
  descripcin!: string
}
export class FiltroCobranzaDto {
  segmento!: FiltroSegmentoDto
  diasVencer!: number
  morosos!: boolean
  vencidos!: boolean
}
