<section class="card rounded mt-4">
  <form class="flex px-4 h-12 items-center gap-2" name="form" #form="ngForm" (submit)="guardar()">
    <div class="flex-grow typo-subhead">Permisos de empresa</div>
    <p-dropdown
      name="empresa"
      [options]="empresas"
      optionLabel="descripcion"
      optionValue="id"
      placeholder="Seleccione la empresa"
      (onChange)="buscarPermisos()"
      [(ngModel)]="empresaId"
    ></p-dropdown>
    <button
      class="button"
      type="submit"
      [disabled]="!modificado"
      [ngClass]="{ 'bg-blue-200 hover:bg-blue-200': !modificado }"
    >
      Editar
    </button>
    <!--div-- class="w-48 flex rounded hover:bg-gris-claro px-2 cursor-pointer">
      <div class="self-center flex-grow text-sm --text-clip">Empresa #2</div>
      <div class="icon">
        <i class="pi pi-caret-down"></i>
      </div>
    </div-->
  </form>
  <div class="flex text-center px-4">
    <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
    <div class="flex-grow">
      <input
        pInputText
        name="filter"
        autocomplete="off"
        [(ngModel)]="filtro"
        (ngModelChange)="filtrar()"
        class="input-filter"
        id="username"
        type="search"
        placeholder=""
      />
    </div>
  </div>
  <div class="max-h-72 h-72 overflow-auto mt-2">
    <auth-usuarios-usuario-empresa-permisos-lista
      [data]="resultados"
      (selected)="seleccionar()"
    ></auth-usuarios-usuario-empresa-permisos-lista>
  </div>
</section>
<p-toast position="bottom-right"></p-toast>
