import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'auth-sesion-finalizar',
  templateUrl: './finalizar.component.html',
  styleUrls: ['./finalizar.component.scss'],
})
export class AuthSesionFinalizarComponent implements OnInit {
  ngOnInit(): void {
    localStorage.removeItem('ng-token')
  }
}
