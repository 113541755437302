import { Injectable } from '@angular/core'
import { fromEvent, merge } from 'rxjs'
import { mapTo } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class NetworkStatusService {
  public readonly onlineChange$ = fromEvent(window, 'online').pipe(mapTo(true))

  public readonly offlineChange$ = fromEvent(window, 'offline').pipe(mapTo(true))

  public readonly statusChange$ = merge<{ online: boolean; offline: boolean }[]>(
    this.onlineChange$.pipe(mapTo({ online: true, offline: false })),
    this.offlineChange$.pipe(mapTo({ online: false, offline: true })),
  )

  public get isOnline() {
    return navigator?.onLine
  }
}
