import { IsNumber, IsString } from 'class-validator'

export class SucursalInventarioListaDto {
  @IsNumber()
  existencia!: number
  @IsString()
  codigo!: string
  @IsNumber()
  minimo!: number
  @IsString()
  nombre!: string
  @IsNumber()
  precioVenta!: number
  @IsNumber()
  costo!: number
}
