import { IsNumber, IsString } from 'class-validator'

export class CatalogoContactoEstatusDto {
  @IsNumber()
  id!: number

  @IsString()
  descripcion!: string

  @IsString()
  codigo!: string
}
