import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ICredecial, ICredecialRespuesta, IUsuarioLogin } from '../../../../types'
import { AuthSesionJwtService } from './jwt.service'

@Injectable({
  providedIn: 'root',
})
export class AuthSesionLoginService {
  constructor(private $http: HttpClient, private jwt: AuthSesionJwtService, private router: Router) {}

  auth(credecial: ICredecial): Observable<ICredecialRespuesta> {
    return this.$http.post<ICredecialRespuesta>(`/api/sesion/login`, credecial)
  }

  unauth(): void {
    this.setSession(null)
    this.router.navigateByUrl('/').then((r) => r)
  }

  isAuth(): boolean {
    return (
      window.localStorage.getItem('ng-token') && this.jwt.isExpired(window.localStorage.getItem('ng-token')) === false
    )
  }

  setSession(data): void {
    if (data) {
      window.localStorage.setItem('ng-token', data.token)
      window.localStorage.setItem('ng-cuenta', data.dominioId)
    } else {
      window.localStorage.removeItem('ng-token')
      window.localStorage.removeItem('ng-cuenta')
    }
  }

  current(): Observable<IUsuarioLogin> {
    return this.$http.get<IUsuarioLogin>(`/api/sesion/current`).pipe(
      map((response) => {
        // this.location.back()
        localStorage.setItem('usuarioId', response.id.toString())
        return response
      }),
    )
  }
}
