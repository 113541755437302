import { IsNumber, IsOptional, IsString } from 'class-validator'

export class ZonaDto {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsString()
  descripcion!: string

  @IsNumber()
  ciudadId!: number | null

  @IsOptional()
  cp?: string
}

export class NuevaCiudadDto {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsString()
  descripcion!: string

  @IsString()
  identificacion!: string
}

export class NuevoEstadoDto {
  @IsOptional()
  id?: number

  @IsString()
  descripcion?: string
}
