function escape(value: string): string {
  return value.replace(/./g, function (c) {
    return '%' + `00${c.charCodeAt(0).toString(16)}`.slice(-2)
  })
}

function unescape(value: string): string {
  return value.replace(/%([0-9A-F]{2})/g, function (_, s) {
    return String.fromCharCode(parseInt(s, 16))
  })
}

function encodeURI(value: string): string {
  return unescape(encodeURIComponent(value))
}

function decodeURI(value: string): string {
  return decodeURIComponent(escape(value))
}

export function base64Encode(value: string): string {
  return window.btoa(encodeURI(value))
}

export function base64Decode(value: string): string {
  return window.atob(decodeURI(value))
}

export function urlBase64Encode(value: string): string {
  value = value.replace(/\+/g, '-')
  value = value.replace(/\//g, '_')
  value = value.replace(/=/g, '')

  return base64Encode(value)
}

export function urlBase64Decode(value: string): string {
  const pad = value.length % 4

  if (pad === 1) throw new Error('Invalid input length')

  value = value.replace(/-/g, '+')
  value = value.replace(/_/g, '/')
  value = value + '='.repeat((4 - pad) % 4)

  return base64Decode(value)
}
