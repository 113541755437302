import { IsNumber, IsOptional, IsString } from 'class-validator'

export class VincularProspectoDataDto {
  id!: number

  foto!: string

  nombre!: string

  @IsOptional()
  @IsNumber()
  plataformasId?: number[]

  @IsOptional()
  @IsString()
  nota?: string
}
export class VincularProspectoPostDto {
  @IsNumber()
  contactoId!: number

  @IsNumber()
  contactoIdNuevo!: number
}
