import { IsNumber, IsString } from 'class-validator'

export class EcommerceCatalogoOrdenDto {
  @IsNumber()
  id!: number

  @IsString()
  descripcion!: string

  @IsString()
  codigo!: string
}
