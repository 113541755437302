import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AuthSesionLoginService } from '../../../sesion/apis/login.service'
import { AuthSesionPermisosService } from '../../../sesion/services/permisos.service'
import { AuthUsuariosUsuariosService } from '../../apis/usuarios.service'
import { IUsuarios } from '../../types'

@Component({
  selector: 'auth-usuarios-usuarios',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss'],
})
export class AuthUsuariosUsuariosComponent implements OnInit {
  data: IUsuarios = []

  constructor(
    public usuariosService: AuthUsuariosUsuariosService,
    private authSesion: AuthSesionLoginService,
    private router: Router,
    private sesionPermisosService: AuthSesionPermisosService,
  ) {}

  ngOnInit(): void {
    if (!this.authSesion.isAuth()) {
      this.router.navigateByUrl('/session/login').then((r) => r)
    }
    this.usuariosService.get().subscribe((data) => {
      this.data = data
      console.log('alou', this.data)
    })
  }
}
