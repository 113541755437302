import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { DecetCRMComponent } from './crm/component/crm.component'

const routes: Routes = [
  {
    path: 'session',
    loadChildren: () => import('libs/auth/src/lib/sesion/sesion.module').then((m) => m.AuthSesionModule),
  },
  {
    path: '',
    redirectTo: '1-1-1',
    pathMatch: 'full',
  },
  {
    path: ':workspace',
    children: [
      {
        path: 'real-estate',
        loadChildren: () => import('./real-estate/real-estate.module').then((m) => m.DecetRealEstateModule),
      },
      {
        path: 'blogs',
        loadChildren: () => import('./blogs/blogs.module').then((m) => m.DecetBlogsModule),
      },
      {
        path: 'customers',
        loadChildren: () => import('apps/pos/src/app/clients/clients.module').then((m) => m.PosClientsModule),
      },
      {
        path: 'contracts',
        loadChildren: () => import('@decet/contratos-browser').then((m) => m.ContratosBrowserModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./usuarios/usuarios.module').then((m) => m.DecetUsuariosModule),
      },
      {
        path: 'pagina-web',
        loadChildren: () => import('./pagina-web/pagina-web.module').then((m) => m.PaginaWebModule),
      },
      {
        path: 'crm',
        component: DecetCRMComponent,
        children: [
          {
            path: '',
            loadChildren: () => import('libs/crm-libreria/src/lib/crm.module').then((m) => m.CrmModule),
          },
        ],
      },
      {
        path: '',
        loadChildren: () => import('apps/pos/src/app/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },

      {
        path: 'catalogos',
        loadChildren: () => import('apps/pos/src/app/catalogos/catalogos.module').then((m) => m.POSCatalogosModule),
      },
      {
        path: 'providers',
        loadChildren: () => import('apps/pos/src/app/proveedores/proveedores.module').then((m) => m.ProveedoresModule),
      },
      {
        path: 'compras',
        loadChildren: () => import('apps/pos/src/app/compras/compras.module').then((m) => m.ComprasModule),
      },
      {
        path: 'ventas',
        loadChildren: () => import('apps/pos/src/app/ventas/ventas.module').then((m) => m.VentasModule),
      },
      {
        path: 'promociones',
        loadChildren: () =>
          import('apps/pos/src/app/promociones/promociones.module').then((m) => m.POSPromocionesModule),
      },
      {
        path: 'mayoreo',
        loadChildren: () => import('apps/pos/src/app/mayoreo/mayoreo.module').then((m) => m.POSMayoreoModule),
      },
      {
        path: 'prueba',
        loadChildren: () => import('apps/pos/src/app/pueba/pueba.module').then((m) => m.PuebaModule),
      },
      {
        path: 'cash-register',
        loadChildren: () =>
          import('apps/pos/src/app/cash-register/cash-register.module').then((m) => m.CashRegisterModule),
      },
      {
        path: 'products',
        loadChildren: () => import('apps/pos/src/app/products/products.module').then((m) => m.PosProductsModule),
      },
      {
        path: 'customers',
        loadChildren: () => import('apps/pos/src/app/clients/clients.module').then((m) => m.PosClientsModule),
      },
      {
        path: 'branch-offices',
        loadChildren: () =>
          import('apps/pos/src/app/branch-offices/branch-offices.module').then((m) => m.BranchOfficesModule),
      },
      {
        path: 'transfer',
        loadChildren: () => import('apps/pos/src/app/transfer/transfer.module').then((m) => m.TransferModule),
      },
    ],
  },
  // {
  //   path: 'test',
  //   loadChildren: () => import('@decet/direcciones-browser').then((m) => m.DireccionesBrowserModule),
  // },
]

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
