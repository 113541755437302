<section *ngIf="data.length">
  <div class="m-4 h-8 flex items-center">
    <span class="p-inputgroup-addon h-full"><i class="pi pi-search"></i></span>
    <input
      class="p-inputtext flex-grow w-full h-full"
      placeholder="Filtrar"
      [(ngModel)]="filtro"
      (ngModelChange)="filtrar()"
      type="search"
      pInputText
    />
    <button class="button ml-2" [routerLink]="['agregar']">Nuevo</button>
  </div>

  <div class="contenedor" style="text-align: center; margin: 30px" *ngIf="!resultados.length">
    <div>
      <i class="fa fa-ban --text-light"></i>
      No se encontrarón datos con su criterio de búsqueda
    </div>
    <br />
    <button class="p-button p-button-outlined" type="button" (click)="filtro = ''; filtrar()">Limpiar filtro</button>
  </div>
  <auth-usuarios-usuarios-lista-item
    *ngFor="let item of resultados"
    [data]="item"
    (click)="usuario(item)"
    [routerLink]="['../usuario', item.id]"
  ></auth-usuarios-usuarios-lista-item>
</section>
<div class="text-align-center" *ngIf="!data.length">
  <p class="typo-title">No hay usuarios registrados en está cuenta</p>
  <button class="p-button p-button-primary" [routerLink]="['usuarios/agregar']">Nuevo usuario</button>
</div>
