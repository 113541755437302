import { Type } from 'class-transformer'
import { IsBoolean, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import {
  CatalogoDto,
  CatalogoFormaPagoDto,
  CatalogoMetodoPagoDto,
  CatalogoMonedaDto,
  CatalogoSucursalDto,
} from '../compras/compras.dto'

export class CatalogoCajaDto extends CatalogoDto {}

export class CatalogoCorteDto extends CatalogoDto {}

export class CatalogoFuenteDto extends CatalogoDto {}

export class CatalogoClienteDto extends CatalogoDto {}

export class VentaDetalleDto {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsString()
  codigo!: string

  @IsString()
  nombre!: string

  @IsOptional()
  @IsNumber()
  cantidad!: number

  @IsOptional()
  @IsNumber()
  importe!: number

  @IsNumber()
  existencia!: number

  @IsNumber()
  impuestos!: number

  @IsNumber()
  costoPromedio!: number

  @IsNumber()
  precio!: number

  @IsNumber()
  precioVenta!: number

  @IsNumber()
  precioSugerido!: number

  @IsString()
  descripcion!: string

  @IsOptional()
  @IsNumber()
  promocionId?: number

  @IsNumber()
  productoId!: number

  @IsNumber()
  descuento!: number

  @IsNumber()
  precioUnitarioFinal!: number //Es el precio de venta menos las promociones o precio mayoreo pertinentes

  @IsBoolean()
  mayoreo!: boolean

  @IsOptional()
  detalleMayoreo?: any
}

export class VentaDetallePago {
  @IsOptional()
  @IsNumber()
  ventaId!: number

  @IsNumber()
  formaPagoId!: number

  @IsNumber()
  importe!: number

  @IsNumber()
  real?: number
}

export class VentaDto {
  @ValidateNested()
  sucursal!: CatalogoSucursalDto

  @ValidateNested()
  caja!: CatalogoCajaDto

  @ValidateNested()
  corte!: CatalogoCorteDto

  @ValidateNested()
  fuente!: CatalogoFuenteDto

  @ValidateNested()
  moneda!: CatalogoMonedaDto

  @ValidateNested()
  formaPago!: CatalogoFormaPagoDto

  @ValidateNested()
  metodoPago!: CatalogoMetodoPagoDto

  @IsOptional()
  @ValidateNested()
  cliente?: CatalogoClienteDto

  @IsNumber()
  subtotal!: number

  @IsNumber()
  descuento!: number

  @IsNumber()
  total!: number | null

  @IsNumber()
  cambio!: number | null

  @IsNumber()
  tipoCambio!: number

  @IsBoolean()
  pagada!: boolean

  @IsOptional()
  pagadaFecha!: Date

  @IsBoolean()
  publicoGeneral!: boolean

  @IsString()
  folioFiscal!: string

  @IsOptional()
  fechaCancelada!: Date

  @IsOptional()
  @IsString()
  horaCancelada!: string

  @IsBoolean()
  cancelada!: boolean

  @ValidateNested()
  @Type(() => VentaDetalleDto)
  ventaDetalle!: VentaDetalleDto[]

  @ValidateNested()
  @Type(() => VentaDetallePago)
  ventaDetallesPagos!: VentaDetallePago[]
}

export class ValidarVentaClienteDto {
  cuentasPagar?: unknown[]

  @IsBoolean()
  vencido!: boolean
}
