<section class="mx-auto" style="max-width: 620px">
  <div class="h-10 flex items-center">
    <h1 class="typo-title">Enviar invitación</h1>
  </div>
  <form class="card rounded p-4 mt-2 flex flex-col gap-2" (ngSubmit)="guardar()">
    <p>Se le enviará una invitación a este usuario al siguiente correo</p>
    <input
      class="p-InputText w-full"
      required
      placeholder="Correo electrónico"
      name="correo"
      [(ngModel)]="data.correo"
      pInputText
    />
    <div class="flex">
      <button class="button" type="submit" [disabled]="!data?.correo">Enviar</button>
    </div>
    <auth-usuarios-usuario-invitacion-confirmar *ngIf="enviar" [data]="data" (cerrar)="cerrar($event)">
    </auth-usuarios-usuario-invitacion-confirmar>
  </form>
</section>

<!--  </app-await>-->
<!--</app-content>-->
